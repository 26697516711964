import React, { useState } from "react";
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CourseDescriptionDialog from "./guardian/CourseDescriptionDialog";
import formatDate from "../utils/dateFormatter";
import { useAuth } from "../contexts/AuthContext";

const GuardianLearnerNarrativesPanel = ({
  value,
  index,
  narratives,
  courseName,
}) => {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(0);
  const [courseDescriptionOpen, setCourseDescriptionOpen] = useState(false);

  const handleChange = (panel) => (newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid
      role="tabpanel"
      id={`section-panel-${index}`}
      aria-labelledby={`section-tab-${index}`}
      hidden={value !== index}
    >
      <Grid item align="left" style={{ marginBottom: "20px" }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setCourseDescriptionOpen(true)}
        >
          Course Description
        </Button>
        <CourseDescriptionDialog
          courseDescriptionOpen={courseDescriptionOpen}
          setCourseDescriptionOpen={setCourseDescriptionOpen}
          courseName={courseName}
        />
      </Grid>
      {narratives && narratives.length ? (
        <Grid item container direction="column">
          {narratives.map((entry, index) => (
            <Accordion
              key={entry.createdOn}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Grid item container direction="row" alignItems="center">
                  <Grid item>
                    <Typography>
                      {`Published ${formatDate(entry.publishedOn)} by ${
                        entry.teacherFirstName
                      } ${entry.teacherLastName}`}
                    </Typography>
                  </Grid>
                  {entry.publishedOn > currentUser.previousLogin && (
                    <Grid item>
                      <Chip
                        icon={
                          <RateReviewIcon
                            sx={{
                              marginLeft: "0.5em",
                              "&&": { color: theme.palette.common.glowColor },
                              backgroundColor: theme.palette.common.darkColor,
                            }}
                          />
                        }
                        label="New Narrative"
                        sx={{
                          marginLeft: "1.5em",
                          color: theme.palette.common.glowColor,
                          backgroundColor: theme.palette.common.darkColor,
                          padding: "5px",
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ whiteSpace: "pre-line" }}
                >{`${entry.analysis}`}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      ) : (
        <Typography>
          There are no teacher narratives provided so far for this course. This
          may be because we are working toward developing skills related to this
          course, or the collection of evidence concerning progress is still
          being gathered.
        </Typography>
      )}
    </Grid>
  );
};

export default GuardianLearnerNarrativesPanel;
