import {
  doc,
  getDoc,
  getDocs,
  collection,
  where,
  query,
} from "firebase/firestore";
import { firestore } from "../../../../firebaseInit";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";

const useFetchAdminData = () => {
  const fetchAdminSummaryData = async (gradeLevels) => {
    // for each grade level, fetch the summary data
    return await Promise.all(
      gradeLevels.map(async (gradeLevel) => {
        const summaryRef = doc(
          firestore,
          "gradeLevelSummaryData",
          `${gradeLevel}-${currentSchoolYear}`
        );
        const summarySnap = await getDoc(summaryRef);

        return { ...summarySnap.data() };
      })
    );
  };

  const fetchAllLearnersInGradeLevel = async (gradeLevel) => {
    const allLearnersInGradeLevel = [];

    // for each grade level, fetch the summary data
    const q = query(
      collection(firestore, "users"),
      where("gradeLevel", "==", gradeLevel),
      where("schoolYear", "==", currentSchoolYear)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      allLearnersInGradeLevel.push({ id: doc.id, ...doc.data() });
    });

    return allLearnersInGradeLevel;
  };

  const fetchGradeLevels = async () => {
    const docRef = doc(firestore, "systemSettings", `${currentSchoolYear}`);
    const docSnap = await getDoc(docRef);

    const sysSettingsData = { ...docSnap.data() };

    return sysSettingsData.gradeLevels;
  };

  return {
    fetchAdminSummaryData,
    fetchAllLearnersInGradeLevel,
    fetchGradeLevels,
  };
};

export default useFetchAdminData;
