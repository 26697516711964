const formatDate = (firebaseTimestamp) => {
  let dateString;
  if (firebaseTimestamp) {
    dateString = firebaseTimestamp.toDate();
  } else {
    return "NO DATE TO SHOW HERE...";
  }

  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export default formatDate;
