import React, {
  Fragment,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import _ from "lodash";
import {
  Grid,
  Container,
  TextField,
  Button,
  Typography,
  Autocomplete,
} from "@mui/material";
import ReactToPrint from "react-to-print";
import { LearnerContext } from "../../../contexts/LearnerContext";
import { useAuth } from "../../../contexts/AuthContext";
import { RatingPropertiesContext } from "../../../contexts/RatingPropertiesContext";
import GuardianPortraitPanel from "../../GuardianPortraitPanel";
import GuardianNarrativesPanel from "../../GuardianNarrativesPanel";
import AdminSummaryPanel from "./AdminSummaryPanel";
import { PrintableLearnerSummary } from "../../PrintableLearnerSummary";

const AdminLandingPage = ({ learnerListing, adminSummaryData }) => {
  const { setCurrentLearnerId, currentLearner } = useContext(LearnerContext);
  const { ratingProperties } = useContext(RatingPropertiesContext);
  const { currentUser } = useAuth();
  const [selectedListedLearner, setSelectedListedLearner] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const componentRef = useRef();

  useEffect(() => {
    // when currentLearner changes, find them in the learner listing and set the value
    if (currentLearner) {
      const foundLearner = _.find(learnerListing, {
        learnerId: currentLearner.learnerId,
      });
      setSelectedListedLearner(foundLearner);
    } else {
      setSelectedListedLearner(null);
    }
  }, [currentLearner, learnerListing]);

  const handleLearnerSelect = (event, newLearner) => {
    if (newLearner) {
      //setSelectedListedLearner(newLearner);
      setCurrentLearnerId(newLearner.learnerId);
    } else {
      //setSelectedListedLearner(null);
      setCurrentLearnerId(null);
    }
  };

  return (
    <Container>
      <Grid item container direction="column" style={{ marginTop: "10em" }}>
        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <Autocomplete
            id="learners"
            value={selectedListedLearner}
            onChange={handleLearnerSelect}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={learnerListing}
            getOptionLabel={(option) =>
              `${option.lastName}, ${option.firstName}`
            }
            style={{ width: 400 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Find a Learner"
                variant="outlined"
              />
            )}
          />
        </Grid>
        {currentLearner ? (
          <Fragment>
            <Grid item container direction="row">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="outlined"
                    onClick="handlePrint"
                    sx={(theme) => ({
                      root: {
                        "&:hover": {
                          color: theme.palette.common.darkColor,
                          background: theme.palette.common.glowColor,
                          borderColor: theme.palette.common.darkColor,
                        },
                      },
                      marginTop: "1em",
                    })}
                  >{`Print Report for ${currentLearner.firstName}`}</Button>
                )}
                content={() => componentRef.current}
              />
              <div style={{ display: "none" }}>
                <PrintableLearnerSummary
                  ref={componentRef}
                  currentLearner={currentLearner}
                />
              </div>
            </Grid>
            {currentLearner.portraits && currentLearner.portraits.length > 0 && (
              <GuardianPortraitPanel
                learnerPortraitEntries={_.orderBy(
                  _.filter(currentLearner.portraits, {
                    isPublished: true,
                  }),
                  ["createdOn"],
                  ["desc"]
                )}
                previousLogin={currentUser.previousLogin}
              />
            )}
            {currentLearner.sections && currentLearner.sections.length > 0 && (
              <GuardianNarrativesPanel
                currentLearner={currentLearner}
                previousLogin={currentUser.previousLogin}
              />
            )}
          </Fragment>
        ) : ratingProperties ? (
          <AdminSummaryPanel
            adminSummaryData={adminSummaryData}
            ratingProperties={ratingProperties}
          />
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
    </Container>
  );
};

export default AdminLandingPage;
