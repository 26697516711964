import React, { useState, useEffect, createContext } from "react";
import _ from "lodash";
import { useAuth } from "../contexts/AuthContext";

export const SectionContext = createContext();

export const SectionProvider = (props) => {
  const { currentUser } = useAuth();
  const [currentSectionId, setCurrentSectionId] = useState(null);

  // be sure we have a currentSectionId established for a teacher
  useEffect(() => {
    if (currentUser && currentUser.role) {
      if (!currentSectionId) {
        // establish a current section depending on user role
        if (currentUser.role === "teacher" && currentUser.sections.length) {
          // set their current section to the first section in their roster array
          setCurrentSectionId(
            _.orderBy(currentUser.sections, ["sectionId", "asc"])[0].sectionId
          );
        } else if (currentUser.role === "guardian") {
          // we will handle this in the guardian components
        }
      }
    }
  }, [currentSectionId, currentUser]);

  return (
    <SectionContext.Provider
      value={{
        currentSectionId,
        setCurrentSectionId,
      }}
    >
      {props.children}
    </SectionContext.Provider>
  );
};
