import { useState } from "react";
import { Typography, Grid, TextField } from "@mui/material";
import GlowButton from "../../custom/GlowButton";
import usePortraitAnalysisEditing from "./hooks/usePortraitAnalysisEditing";

const PortraitAnalysisEditing = ({
  portraitData,
  setIsEditing,
  setCreationControlsLocked,
}) => {
  const { updatePortrait } = usePortraitAnalysisEditing();

  const [editedAnalysis, setEditedAnalysis] = useState(portraitData.analysis);
  const [editedAnalysisError, setEditedAnalysisError] = useState("");

  const handleAnalysisChange = (e) => {
    setEditedAnalysis(e.target.value);
  };

  const handleAnalysisSave = () => {
    if (
      !editedAnalysis ||
      !editedAnalysis.length ||
      editedAnalysis.length === 0
    ) {
      return setEditedAnalysisError("The analysis cannot be blank.");
    }
    if (editedAnalysis === portraitData.analysis) {
      if (editedAnalysisError.length > 0) {
        setEditedAnalysisError("");
      }

      setCreationControlsLocked(false);
      return setIsEditing(false);
    }
    if (editedAnalysisError.length > 0) {
      setEditedAnalysisError("");
    }
    updatePortrait({ ...portraitData, analysis: editedAnalysis.trim() });
    setCreationControlsLocked(false);
  };

  return (
    <Grid item container direction="column">
      <Grid item sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <TextField
          multiline
          error={editedAnalysisError.length > 0}
          fullWidth
          value={editedAnalysis}
          id="outlined-analysis-edit"
          label="Editing Learner Analysis"
          onChange={(e) => handleAnalysisChange(e)}
          helperText={editedAnalysisError}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          <GlowButton variant="outlined" onClick={handleAnalysisSave}>
            Save Edits
          </GlowButton>
        </Grid>
        <Grid item>
          <GlowButton
            variant="outlined"
            onClick={() => {
              setCreationControlsLocked(false);
              setIsEditing(false);
            }}
          >
            Cancel
          </GlowButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PortraitAnalysisEditing;
