import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

export default function SignInButtonMenu() {
  const theme = useTheme();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          backgroundColor: theme.palette.common.mediumColor,
          color: theme.palette.common.glowColor,
          "&:hover": {
            backgroundColor: theme.palette.common.glowColor,
            color: "black",
          },
        }}
      >
        Sign In
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ marginTop: "6px" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/staff-signin");
          }}
        >
          Staff Member
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/guardian-signin");
          }}
        >
          Guardian
        </MenuItem>
      </Menu>
    </>
  );
}
