import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const AdminRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (!currentUser || !currentUser.role === "admin") {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default AdminRoute;
