import { Grid, TextField } from "@mui/material";
import GlowButton from "../../custom/GlowButton";
import useAnalysisEditing from "./hooks/useNarrativeAnalysisEditing";
import useCreateDeleteNarrative from "./hooks/useCreateDeleteNarrative";

const NarrativeAnalysisDisplay = ({
  narrativeData,
  setIsEditing,
  creationControlsLocked,
  setCreationControlsLocked,
}) => {
  const { updateNarrative } = useAnalysisEditing();
  const { deleteNarrative } = useCreateDeleteNarrative();

  const handlePublishToggleClick = () => {
    if (narrativeData.isPublished) {
      // we're going back to draft
      updateNarrative(
        { ...narrativeData, isPublished: false },
        "publishToggle"
      );
    } else {
      // we're publishing
      updateNarrative({ ...narrativeData, isPublished: true }, "publishToggle");
    }
  };

  const handleDeleteClick = () => {
    deleteNarrative(narrativeData);
  };

  return (
    <Grid item container direction="column">
      <Grid item sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: true }}
          multiline
          fullWidth
          value={narrativeData.analysis}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          {narrativeData.isPublished ? (
            <GlowButton
              variant="outlined"
              onClick={handlePublishToggleClick}
              disabled={creationControlsLocked}
            >
              Set As Draft
            </GlowButton>
          ) : (
            <GlowButton
              variant="outlined"
              onClick={handlePublishToggleClick}
              disabled={creationControlsLocked}
            >
              Publish
            </GlowButton>
          )}
        </Grid>
        <Grid item>
          <GlowButton
            variant="outlined"
            onClick={() => {
              setIsEditing(true);
              setCreationControlsLocked(true);
            }}
            disabled={creationControlsLocked}
          >
            Edit
          </GlowButton>
        </Grid>
        <Grid item>
          <GlowButton
            variant="outlined"
            onClick={handleDeleteClick}
            disabled={creationControlsLocked}
          >
            Delete
          </GlowButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NarrativeAnalysisDisplay;
