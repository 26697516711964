import React, { useState, useReducer, createContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link } from "@mui/material";
import { firestore } from "../firebaseInit";
import { doc, onSnapshot } from "firebase/firestore";
import { localVersion } from "../config/localVersion";
import { currentSchoolYear } from "../config/currentSchoolYear";

export const MessageContext = createContext();

const SET_MSG = "SET_MSG";
const REMOVE_MSG = "REMOVE_MSG";

const msgReducer = (state, action) => {
  switch (action.type) {
    case SET_MSG:
      return action.payload;
    case REMOVE_MSG:
      return null;
    default:
      return state;
  }
};

const versionMessage = (
  <Typography variant="body1">
    There is a critical update available for this app.{" "}
    <Link
      style={{ color: "blue", cursor: "pointer" }}
      onClick={() => window.location.reload(true)}
    >
      Please click here
    </Link>{" "}
    to load the updated version.
  </Typography>
);

// severity options: info, warning, error, success, version

export const MessageProvider = (props) => {
  const initialState = null;
  const [state, dispatch] = useReducer(msgReducer, initialState);
  const [cloudVersion, setCloudVersion] = useState(null);

  // subscribe to version changes
  useEffect(() => {
    const unsubscribe = setVersionListener();
    return () => {
      unsubscribe();
    };
  }, []);

  // analyze version changes
  useEffect(() => {
    if (cloudVersion && cloudVersion.localeCompare(localVersion) !== 0) {
      // set an update message to UI
      setMessage("version", versionMessage, null);
    }
  }, [cloudVersion]);

  // establishes a snapshot listener, which gets changes to the cloud version in realtime
  const setVersionListener = () => {
    return onSnapshot(
      doc(firestore, `systemSettings`, `${currentSchoolYear}`),
      (doc) => {
        // be sure to keep auth provider data integrated into userDoc
        const versionData = { ...doc.data() };
        setCloudVersion(versionData.versionNumber);
      },
      (error) => {
        // ...
      }
    );
  };

  const setMessage = (severity = "info", content, timeout = 5000) => {
    dispatch({
      type: SET_MSG,
      payload: { severity, content, timeout },
    });
    // keep version messages displayed indefinitely
    if (severity !== "version") {
      setTimeout(() => {
        dispatch({
          type: REMOVE_MSG,
        });
      }, timeout);
    }
  };

  return (
    <MessageContext.Provider
      value={{
        message: state,
        setMessage,
      }}
    >
      {props.children}
    </MessageContext.Provider>
  );
};
