import React from "react";
import _ from "lodash";
import logo from "../assets/gatherLogoColor.png";
import formatDate from "../utils/dateFormatter";

const setOrderedSections = (currentLearner) => {
  // each section needs to have only published narratives in desc order by publishedOn
  return currentLearner.sections.map((section) => {
    const filteredNarratives = _.filter(section.narratives, {
      isPublished: true,
    });
    const orderedNarratives = _.orderBy(
      filteredNarratives,
      ["publishedOn"],
      ["desc"]
    );
    const narrativesWithTeacherNames = orderedNarratives.map((narrative) => {
      const foundTeacher = _.find(section.teachers, {
        teacherId: narrative.teacherId,
      });
      return {
        ...narrative,
        teacherFirstName: foundTeacher.firstName,
        teacherLastName: foundTeacher.lastName,
      };
    });

    return {
      narratives: narrativesWithTeacherNames,
      courseName: section.courseName,
      sectionId: section.sectionId,
    };
  });
};

const setOrderedPortraits = (portraits) => {
  const filteredPortraits = _.filter(portraits, { isPublished: true });
  const orderedPortraits = filteredPortraits.length
    ? _.orderBy(filteredPortraits, ["publishedOn"], ["desc"])
    : [];

  return orderedPortraits;
};

export class PrintableLearnerSummary extends React.PureComponent {
  render() {
    const { currentLearner } = this.props;
    const orderedSections = setOrderedSections(currentLearner);
    const orderedPortraits =
      currentLearner.portraits && currentLearner.portraits.length > 0
        ? setOrderedPortraits(currentLearner.portraits)
        : [];

    return (
      <div style={{ pageBreakAfter: "always" }}>
        <img src={logo} width="200px" alt="logo" />
        <h1>{`Gather Progress Report for ${currentLearner.firstName} ${currentLearner.lastName}`}</h1>
        <p>
          Gather is a narrative-based system designed to provide deep insight
          into the progress of your child's development.
        </p>
        <p>{`This report was generated On: ${new Date().toLocaleString()}`}</p>
        {orderedPortraits &&
        orderedPortraits.length &&
        orderedPortraits.length > 0 ? (
          <div>
            <h2>Learner Portrait</h2>
            <h4>
              These entries are not related to a specific subject, rather they
              speak to the overall aspects of how your child is progressing. In
              this section, teachers often detail the social, emotional, and
              learning-skill development of the young person.
            </h4>
            {orderedPortraits.map((portrait) => (
              <div key={portrait.rootPortraitId}>
                <p>{`Published On: ${formatDate(portrait.publishedOn)} by ${
                  portrait.teacherFirstName
                } ${portrait.teacherLastName}`}</p>
                <p>{portrait.analysis}</p>
              </div>
            ))}
          </div>
        ) : null}
        <h2>Course Narratives</h2>
        <h4>
          Each course and the entire history of narratives published throughout
          the year is provided here:
        </h4>
        {orderedSections.map((section) => (
          <div key={section.sectionId}>
            <h5>{section.courseName}</h5>
            {section.narratives && section.narratives.length > 0 ? (
              section.narratives.map((narrative) => (
                <div key={narrative.rootNarrativeId}>
                  <p>{`Published On: ${formatDate(narrative.publishedOn)} by ${
                    narrative.teacherFirstName
                  } ${narrative.teacherLastName}`}</p>
                  <p>{narrative.analysis}</p>
                </div>
              ))
            ) : (
              <p>
                There are no teacher narratives provided so far for this course.
                This may be because we are working toward developing skills
                related to this course, or the collection of evidence concerning
                progress is still being gathered.
              </p>
            )}
          </div>
        ))}
      </div>
    );
  }
}
