import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { AppBar, Tabs, Tab, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SectionContext } from "../contexts/SectionContext";
import GuardianLearnerNarrativesPanel from "./GuardianLearnerNarrativesPanel";

const GuardianNarrativesPanel = ({ currentLearner }) => {
  const theme = useTheme();
  const { currentSectionId, setCurrentSectionId } = useContext(SectionContext);

  const [value, setValue] = useState(0);
  const [orderedSections, setOrderedSections] = useState(null);

  // set a currentSectionId if we don't have one, and each time we change a learner
  useEffect(() => {
    if (
      orderedSections &&
      orderedSections.length &&
      orderedSections.length > 0
    ) {
      setCurrentSectionId(orderedSections[0].sectionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLearner, orderedSections]);

  // be sure our sections are ordered by alpha with courseName
  useEffect(() => {
    // each section needs to have only published narratives
    // in desc order by publishedOn
    const orderedSections = currentLearner.sections.map((section) => {
      const filteredNarratives = _.filter(section.narratives, {
        isPublished: true,
      });

      const orderedNarratives = _.orderBy(
        filteredNarratives,
        ["publishedOn"],
        ["desc"]
      );

      if (orderedNarratives && orderedNarratives.length > 0) {
        const narrativesWithTeacherNames = orderedNarratives.map(
          (narrative) => {
            const foundTeacher = _.find(section.teachers, {
              teacherId: narrative.teacherId,
            });

            return {
              ...narrative,
              teacherFirstName: foundTeacher.firstName,
              teacherLastName: foundTeacher.lastName,
            };
          }
        );

        return {
          narratives: narrativesWithTeacherNames,
          courseName: section.courseName,
          sectionId: section.sectionId,
        };
      }

      return {
        narratives: [],
        courseName: section.courseName,
        sectionId: section.sectionId,
      };
    });

    setOrderedSections(_.orderBy(orderedSections, ["courseName"], ["asc"]));
  }, [currentLearner]);

  // set the value for the tab selector if we have a currentSectionId
  useEffect(() => {
    if (currentSectionId && orderedSections) {
      // find index where currentSectionId resides
      const index = _.findIndex(orderedSections, {
        sectionId: currentSectionId,
      });

      setValue(index);
    }
  }, [currentLearner, currentSectionId, orderedSections]);

  const handleChange = (event, newValue) => {
    setCurrentSectionId(orderedSections[newValue].sectionId);
  };

  return (
    <Grid
      item
      container
      direction="column"
      sx={{ marginTop: "3em", marginBottom: "5em" }}
    >
      <Grid item>
        <Typography variant="h4" style={{ marginBottom: "0.5em" }}>
          Course Narratives
        </Typography>
      </Grid>
      {orderedSections &&
        orderedSections.length &&
        orderedSections.length > 0 &&
        value !== -1 && (
          <Grid item>
            <AppBar position="static" style={{ marginBottom: "3em" }}>
              <Tabs
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: theme.palette.common.glowColor,
                  },
                }}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: theme.palette.common.glowColor,
                  },
                }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
                aria-label="scrollable guardian section tabs"
              >
                {orderedSections.map((section, index) => {
                  return (
                    <Tab
                      sx={{ color: theme.palette.common.mediumColor }}
                      key={index}
                      label={section.courseName}
                      id={`section-tab-${section.sectionId}`}
                      aria-controls={`section-tabpanel-${section.sectionId}`}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            {orderedSections.map((section) => (
              <GuardianLearnerNarrativesPanel
                key={section.sectionId}
                value={section.sectionId}
                narratives={section.narratives}
                courseName={section.courseName}
                index={currentSectionId}
              />
            ))}
          </Grid>
        )}
    </Grid>
  );
};

export default GuardianNarrativesPanel;
