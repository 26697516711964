import React, { useState, useEffect, createContext, useContext } from "react";
import { firestore } from "../firebaseInit";
import { useAuth } from "../contexts/AuthContext";
import { SectionContext } from "../contexts/SectionContext";
import { LoadingContext } from "../contexts/LoadingContext";
import { currentSchoolYear } from "../config/currentSchoolYear";
import { doc, onSnapshot } from "firebase/firestore";

export const NarrativeStartersContext = createContext();

let unsubscribe = null;

export const NarrativeStartersProvider = (props) => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);

  const [currentStarters, setCurrentStarters] = useState([]);

  // establishes a snapshot listener for narrative starters
  // depending on the currentSectionId
  const setStartersListener = () => {
    setIsLoading(true);

    return onSnapshot(
      doc(
        firestore,
        "narrativeStarters",
        `${currentSectionId}-${currentSchoolYear}`
      ),
      (doc) => {
        const docData = { ...doc.data() };

        if (docData && docData.narrativeStarters) {
          const fetchedStarters = [...doc.data().narrativeStarters];
          setIsLoading(false);
          return setCurrentStarters(fetchedStarters);
        }
        setIsLoading(false);
        return setCurrentStarters([]);
      },
      (error) => {
        // ...
        setIsLoading(false);
      }
    );
  };

  // be sure we have narrative starters established for a teacher
  useEffect(() => {
    if (currentUser && currentUser.role && currentUser.role === "teacher") {
      if (unsubscribe) {
        unsubscribe();
      }
      // set their current learner to the first learner in their learners array
      unsubscribe = setStartersListener();
    } else {
      setCurrentStarters(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectionId]);

  return (
    <NarrativeStartersContext.Provider
      value={{
        currentStarters,
      }}
    >
      {props.children}
    </NarrativeStartersContext.Provider>
  );
};
