import React, { Fragment, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import ReactToPrint from "react-to-print";
import { Typography, Button, Grid, Container } from "@mui/material";
//import { useAuth } from "../../contexts/AuthContext";
import { LearnerContext } from "../../contexts/LearnerContext";
//import { LoadingContext } from "../../contexts/LoadingContext";
import GuardianLearnerSelector from "./GuardianLearnerSelector";
import GuardianPortraitPanel from "../GuardianPortraitPanel";
import GuardianNarrativesPanel from "../GuardianNarrativesPanel";
import useGuardianLandingPage from "./hooks/useGuardianLandingPage";
import { PrintableLearnerSummary } from "../PrintableLearnerSummary";

const GuardianLandingPage = () => {
  const { currentLearner, setCurrentLearnerId } = useContext(LearnerContext);
  const { fetchLearnerPortraits, learnerPortraits } = useGuardianLandingPage();
  const componentRef = useRef();

  useEffect(() => {
    if (currentLearner) {
      fetchLearnerPortraits();
    }
  }, [currentLearner]);

  return (
    <Container style={{ marginTop: "160px" }}>
      {currentLearner ? (
        <Fragment>
          <GuardianLearnerSelector
            currentLearner={currentLearner}
            setCurrentLearnerId={setCurrentLearnerId}
          />
          <Grid item sx={{ marginTop: "1em", marginBottom: "1em" }}>
            <Typography variant="body2" sx={{ fontSize: "18px" }}>
              For security purposes, please remember to sign out of Gather at
              the end of each session.
            </Typography>
          </Grid>
          <Grid item container direction="row">
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outlined"
                  onClick="handlePrint"
                >{`Print Report for ${currentLearner.firstName}`}</Button>
              )}
              content={() => componentRef.current}
            />
            <div style={{ display: "none" }}>
              <PrintableLearnerSummary
                ref={componentRef}
                currentLearner={currentLearner}
              />
            </div>
          </Grid>
          {learnerPortraits && learnerPortraits.length > 0 && (
            <GuardianPortraitPanel learnerPortraitEntries={learnerPortraits} />
          )}
          {currentLearner.sections && currentLearner.sections.length > 0 && (
            <GuardianNarrativesPanel currentLearner={currentLearner} />
          )}
        </Fragment>
      ) : (
        <Typography variant="h4" sx={{ marginTop: "3em", marginBottom: "5em" }}>
          There are no learners associated to your Gather account. If you feel
          that this is not correct, please <Link to="/support">click here</Link>{" "}
          for information on how to request assistance.
        </Typography>
      )}
    </Container>
  );
};

export default GuardianLandingPage;
