import _ from "lodash";
import { useContext, useState } from "react";
import { firestore } from "../../firebaseInit";
import { getDoc, doc } from "firebase/firestore";
import { currentSchoolYear } from "../../config/currentSchoolYear";
import { LoadingContext } from "../../contexts/LoadingContext";
import { SectionContext } from "../../contexts/SectionContext";
import { MessageContext } from "../../contexts/MessageContext";
import { useAuth } from "../../contexts/AuthContext";

const useCourseDescriptionDialog = () => {
  const { currentSectionId } = useContext(SectionContext);

  const [courseDescription, setCourseDescription] = useState("");

  const fetchCourseDescription = async () => {
    const courseDocId = currentSectionId.slice(4, 10);

    const docRef = doc(
      firestore,
      "courses",
      `${courseDocId}-${currentSchoolYear}`
    );
    const docSnap = await getDoc(docRef);

    return setCourseDescription(docSnap.data().description);
  };

  return {
    fetchCourseDescription,
    courseDescription,
  };
};

export default useCourseDescriptionDialog;
