import _ from "lodash";
import { useContext, useState } from "react";
import { firestore } from "../../../firebaseInit";
import { collection, where, orderBy, getDocs, query } from "firebase/firestore";
import { LoadingContext } from "../../../contexts/LoadingContext";
import { SectionContext } from "../../../contexts/SectionContext";
import { LearnerContext } from "../../../contexts/LearnerContext";
import { currentSchoolYear } from "../../../config/currentSchoolYear";
import { MessageContext } from "../../../contexts/MessageContext";

const useGuardianLandingPage = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { currentLearner } = useContext(LearnerContext);
  const { setMessage } = useContext(MessageContext);

  const [learnerPortraits, setLearnerPortraits] = useState([]);

  const fetchLearnerPortraits = async () => {
    setIsLoading(true);

    try {
      const fetchedPortraits = [];
      const portraitsRef = collection(firestore, "portraits");

      const q = query(
        portraitsRef,
        where("learnerId", "==", currentLearner.learnerId),
        where("isPublished", "==", true),
        where("schoolYear", "==", currentSchoolYear),
        orderBy("publishedOn", "desc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        fetchedPortraits.push({ id: doc.id, ...doc.data() });
      });

      setLearnerPortraits(fetchedPortraits);

      setIsLoading(false);
    } catch (err) {
      setMessage(
        "error",
        `There was an error loading the learner portraits: ${err.message}`
      );

      setIsLoading(false);
    }
  };

  return {
    fetchLearnerPortraits,
    learnerPortraits,
  };
};

export default useGuardianLandingPage;
