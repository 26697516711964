import React, { useRef, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Grid,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "../../contexts/AuthContext";
import { MessageContext } from "../../contexts/MessageContext";
import { LoadingContext } from "../../contexts/LoadingContext";

const GuardianSigninPage = () => {
  const theme = useTheme();
  const { signinUserWithEmailAndPassword } = useAuth();
  const { setMessage } = useContext(MessageContext);
  const { setIsLoading } = useContext(LoadingContext);

  const emailRef = useRef();
  const passwordRef = useRef();

  const handleSignIn = async (e) => {
    e.preventDefault();

    if (
      passwordRef.current.value.length === 0 ||
      emailRef.current.value.length === 0
    ) {
      return setMessage(
        "error",
        "Email and password are both required for sign in...",
        5000
      );
    }

    try {
      // set loading status to true
      setIsLoading(true);

      await signinUserWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );

      // set loading status to false
      setIsLoading(false);
    } catch (err) {
      // set loading status to false
      setIsLoading(false);

      setMessage(
        "error",
        `There was an error during signin, please check your email and password`,
        5000
      );
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "200px" }}>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h4">
            Guardian Sign In
          </Typography>
        </Grid>
        <Grid item align="center">
          <Typography component="h2" variant="body1">
            Please be sure to register your email with the "Sign Up" link below
            the first time you visit Gather. You can simply sign in thereafter.
          </Typography>
        </Grid>
        <Grid item sx={{ width: "100%", marginTop: "2em" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            inputRef={emailRef}
            autoFocus
          />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            inputRef={passwordRef}
          />
        </Grid>
        <Grid item sx={{ width: "100%", marginTop: "1.5em" }}>
          <Button
            onClick={handleSignIn}
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In with Email
          </Button>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs align="left">
            <Link component={RouterLink} to="/" variant="body2">
              Cancel
            </Link>
          </Grid>
          <Grid item xs align="center">
            <Link component={RouterLink} to="/forgot-password" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item xs align="right">
            <Link component={RouterLink} to="/guardian-signup" variant="body2">
              {"New Account Sign Up"}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GuardianSigninPage;
