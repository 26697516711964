import _ from "lodash";
import { useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  collection,
  Timestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { useAuth } from "../../../../contexts/AuthContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { RatingPropertiesContext } from "../../../../contexts/RatingPropertiesContext";

const useCreateDeleteNarrative = () => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);
  const { ratingProperties } = useContext(RatingPropertiesContext);

  const fetchOpeningStatement = (learner) => {
    let performanceDescription = null;

    // compile the narrative data using the learner's course
    // rating and the narrative starter text
    const foundCourseProperty = _.find(ratingProperties, {
      rating: learner.courseRating,
    });

    // we need to find and replace the variable for the learner's name
    // within the course description

    if (foundCourseProperty) {
      performanceDescription = _.replace(
        foundCourseProperty.description,
        "$LEARNER_NAME",
        learner.firstName
      );
    }

    return performanceDescription ? performanceDescription : null;
  };

  const createNewNarrative = async (
    openingStatement,
    baseAnalysis,
    isPublished,
    learner
  ) => {
    try {
      const analysis = `${openingStatement}\n\n${baseAnalysis}`;

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      const newNarrativeDocRef = doc(collection(firestore, "narratives"));

      const narrativeCollectionData = {
        analysis,
        createdOn: currentTimestamp,
        isPublished,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: isPublished ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
      };

      const narrativeLearnerData = {
        rootNarrativeId: newNarrativeDocRef.id,
        analysis,
        createdOn: currentTimestamp,
        isPublished,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: isPublished ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
      };

      // fetch the learner's doc so that we have the original section
      // object to remove from their sections array

      // establish the learner doc ref
      const learnerDocRef = doc(
        firestore,
        "users",
        `${learner.learnerId}-${currentSchoolYear}`
      );

      const fetchedLearnerSnap = await getDoc(learnerDocRef);

      const fetchedLearner = {
        id: fetchedLearnerSnap.id,
        ...fetchedLearnerSnap.data(),
      };

      const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
        sectionId: currentSectionId,
      });

      // update the original learner section object to include the new narrative
      // if there are no narratives, just add the new one,
      // otherwise, append the narrative to the array of narratives
      let updatedLearnerSectionObject = null;

      if (
        originalLearnerSectionObject.narratives &&
        originalLearnerSectionObject.narratives.length > 0
      ) {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: [
            ...originalLearnerSectionObject.narratives,
            narrativeLearnerData,
          ],
        };
      } else {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: [narrativeLearnerData],
        };
      }

      // we need to update the sections collection with the new narrative
      // data for the learner

      // fetch the current section doc, so we can have the original section
      // array object to remove

      // establish the section doc ref
      const sectionDocRef = doc(
        firestore,
        "sections",
        `${currentSectionId}-${currentSchoolYear}`
      );

      const fetchedSectionSnap = await getDoc(sectionDocRef);

      const fetchedSection = {
        id: fetchedSectionSnap.id,
        ...fetchedSectionSnap.data(),
      };

      const originalSectionRosterObject = _.find(fetchedSection.roster, {
        learnerId: learner.learnerId,
      });

      const updatedSectionRosterObject = {
        ...originalSectionRosterObject,
        latestNarrativeCreatedOn: currentTimestamp,
        latestNarrativeId: newNarrativeDocRef.id,
        latestNarrativeLastEditedOn: null,
        latestNarrativePublishedOn: isPublished ? currentTimestamp : null,
      };

      // we need to update the teacher's section object with the new narrative
      // data for the learner

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // find the current teacher section instance, to obtain the original section
      // instance to remove
      const originalTeacherSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // create the updated instance to the teacher section object
      const updatedTeacherSectionObject = {
        ...originalTeacherSectionObject,
        roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
          if (rosteredLearner.learnerId === learner.learnerId) {
            return {
              ...rosteredLearner,
              latestNarrativeCreatedOn: currentTimestamp,
              latestNarrativeId: newNarrativeDocRef.id,
              latestNarrativeLastEditedOn: null,
              latestNarrativePublishedOn: isPublished ? currentTimestamp : null,
            };
          } else {
            return rosteredLearner;
          }
        }),
      };

      await runTransaction(firestore, async (transaction) => {
        // save the narrative to the narrative collection
        transaction.set(newNarrativeDocRef, narrativeCollectionData);

        // for the learner doc, remove the original section object from
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayRemove(originalLearnerSectionObject),
        });

        // for the learner doc, add the updated section object to
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayUnion(updatedLearnerSectionObject),
        });

        // for the sections doc, remove the original roster element
        transaction.update(sectionDocRef, {
          roster: arrayRemove(originalSectionRosterObject),
        });

        // for the sections doc, add the updated roster element
        transaction.update(sectionDocRef, {
          roster: arrayUnion(updatedSectionRosterObject),
        });

        // for the teacher doc, remove the original section object from
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        // for the teacher doc, add the updated section object to
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });

      setIsLoading(false);

      setMessage(
        "success",
        "New learner narrative has been saved successfully."
      );

      return { status: "success" };
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save the new learner narrative: ${err.message}`
      );

      setIsLoading(false);

      return { status: "error" };
    }
  };

  const deleteNarrative = async (narrativeData) => {
    // we may need to determine a new latest narrative, if the narrative
    // being deleted is the current latest. We also need to know if the
    // latest narrative is the last remaining narrative, so...

    try {
      setIsLoading(true);

      let isLatestNarrative = false;
      let isLastNarrative = false;
      let newLatestNarrative = null;
      let newNarrativeSummary = null;
      let sectionDocRef = null;
      let originalSectionRosterObject = null;
      let updatedSectionRosterObject = null;
      let teacherDocRef = null;
      let originalTeacherSectionObject = null;
      let updatedTeacherSectionObject = null;

      const currentSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      const foundCurrentLearner = _.find(currentSectionObject.roster, {
        learnerId: narrativeData.learnerId,
      });

      if (foundCurrentLearner.latestNarrativeId === narrativeData.id) {
        isLatestNarrative = true;
      }

      if (isLatestNarrative) {
        // we need to determine if this is the last narrative for this learner
        // in this section, so...

        // establish the learner doc ref
        const learnerDocRef = doc(
          firestore,
          "users",
          `${narrativeData.learnerId}-${currentSchoolYear}`
        );

        const learnerDoc = await getDoc(learnerDocRef);

        const learner = learnerDoc.data();

        const foundLearnerSection = _.find(learner.sections, {
          sectionId: currentSectionId,
        });

        const foundLearnerSectionNarratives = _.filter(
          foundLearnerSection.narratives,
          {
            sectionId: currentSectionId,
          }
        );

        if (
          foundLearnerSectionNarratives &&
          foundLearnerSectionNarratives.length === 1
        ) {
          isLastNarrative = true;
        } else {
          const newOrderedNarrativeArray = _.orderBy(
            _.compact(
              foundLearnerSectionNarratives.map((narrative) => {
                if (narrative.rootNarrativeId !== narrativeData.id) {
                  return narrative;
                }
                return null;
              })
            ),
            ["createdOn", "desc"]
          );

          newLatestNarrative = newOrderedNarrativeArray.pop();
        }
      }

      // we can now carry out the operations as needed based on the
      // context of the situation...

      // establish the narrative doc ref
      const narrativeDocRef = doc(firestore, "narratives", narrativeData.id);

      // narrative needs deleted from learner section array

      // fetch the original learner doc and isolate the section object
      // from the sections array

      // establish the learner doc ref
      const learnerDocRef = doc(
        firestore,
        "users",
        `${narrativeData.learnerId}-${currentSchoolYear}`
      );

      const fetchedLearnerSnap = await getDoc(learnerDocRef);

      const fetchedLearner = {
        id: fetchedLearnerSnap.id,
        ...fetchedLearnerSnap.data(),
      };

      const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
        sectionId: currentSectionId,
      });

      // update the original learner section object to include the new narrative
      // if there are no narratives, just add the new one,
      // otherwise, append the narrative to the array of narratives
      let updatedLearnerSectionObject = null;

      if (isLastNarrative) {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: [],
        };
      } else {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: _.compact(
            originalLearnerSectionObject.narratives.map((narrative) => {
              if (narrative.rootNarrativeId !== narrativeData.id) {
                return narrative;
              }
              return null;
            })
          ),
        };
      }

      // if this is the last or latest, we need to update the section doc
      // and the teacher (currentUser) sections array
      if (isLatestNarrative) {
        newNarrativeSummary = {
          latestNarrativeCreatedOn: isLastNarrative
            ? null
            : newLatestNarrative.createdOn,
          latestNarrativeId: isLastNarrative
            ? null
            : newLatestNarrative.rootNarrativeId,
          latestNarrativeLastEditedOn: isLastNarrative
            ? null
            : newLatestNarrative.lastEditedOn,
          latestNarrativePublishedOn: isLastNarrative
            ? null
            : newLatestNarrative.publishedOn,
        };

        // establish the section doc ref
        sectionDocRef = doc(
          firestore,
          "sections",
          `${currentSectionId}-${currentSchoolYear}`
        );

        const fetchedSectionSnap = await getDoc(sectionDocRef);

        const fetchedSection = {
          id: fetchedSectionSnap.id,
          ...fetchedSectionSnap.data(),
        };

        originalSectionRosterObject = _.find(fetchedSection.roster, {
          learnerId: narrativeData.learnerId,
        });

        updatedSectionRosterObject = {
          ...originalSectionRosterObject,
          latestNarrativeCreatedOn:
            newNarrativeSummary.latestNarrativeCreatedOn,
          latestNarrativeId: newNarrativeSummary.latestNarrativeId,
          latestNarrativeLastEditedOn:
            newNarrativeSummary.latestNarrativeLastEditedOn,
          latestNarrativePublishedOn:
            newNarrativeSummary.latestNarrativePublishedOn,
        };

        // we need to update the teacher's section object with the new narrative
        // data for the learner

        // establish the teacher doc ref
        teacherDocRef = doc(
          firestore,
          "users",
          `${currentUser.teacherId}-${currentSchoolYear}`
        );

        // find the current teacher section instance, to obtain the original section
        // instance to remove
        originalTeacherSectionObject = _.find(currentUser.sections, {
          sectionId: currentSectionId,
        });

        // create the updated instance to the teacher section object
        updatedTeacherSectionObject = {
          ...originalTeacherSectionObject,
          roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
            if (rosteredLearner.learnerId === narrativeData.learnerId) {
              return {
                ...rosteredLearner,
                latestNarrativeCreatedOn:
                  newNarrativeSummary.latestNarrativeCreatedOn,
                latestNarrativeId: newNarrativeSummary.latestNarrativeId,
                latestNarrativeLastEditedOn:
                  newNarrativeSummary.latestNarrativeLastEditedOn,
                latestNarrativePublishedOn:
                  newNarrativeSummary.latestNarrativePublishedOn,
              };
            } else {
              return rosteredLearner;
            }
          }),
        };
      }

      // we can run the transactions now...
      await runTransaction(firestore, async (transaction) => {
        // narrative doc needs deleted from narratives collection
        transaction.delete(narrativeDocRef);

        // for the learner doc, remove the original section object from
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayRemove(originalLearnerSectionObject),
        });

        // for the learner doc, add the updated section object to
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayUnion(updatedLearnerSectionObject),
        });

        if (isLatestNarrative) {
          // for the sections doc, remove the original roster element
          transaction.update(sectionDocRef, {
            roster: arrayRemove(originalSectionRosterObject),
          });

          // for the sections doc, add the updated roster element
          transaction.update(sectionDocRef, {
            roster: arrayUnion(updatedSectionRosterObject),
          });

          // for the teacher doc, remove the original section object from
          // their sections array
          transaction.update(teacherDocRef, {
            sections: arrayRemove(originalTeacherSectionObject),
          });

          // for the teacher doc, add the updated section object to
          // their sections array
          transaction.update(teacherDocRef, {
            sections: arrayUnion(updatedTeacherSectionObject),
          });
        }
      });

      setMessage("success", `The narrative has been successfully deleted.`);

      setIsLoading(false);
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to delete the learner narrative: ${err.message}`
      );

      setIsLoading(false);
    }
  };

  return { fetchOpeningStatement, createNewNarrative, deleteNarrative };
};

export default useCreateDeleteNarrative;
