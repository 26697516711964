import React, { useState, useEffect, useContext, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { currentSchoolYear } from "../../../config/currentSchoolYear";
import useFetchAdminData from "./hooks/useFetchAdminData";
import { LoadingContext } from "../../../contexts/LoadingContext";
import AdminReportsContainer from "./AdminReportsContainer";

function AdminReportsSelector() {
  const { fetchAllLearnersInGradeLevel, fetchGradeLevels } =
    useFetchAdminData();

  const { setIsLoading } = useContext(LoadingContext);

  const componentRef = useRef();

  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedGradeLevel, setSelectedGradeLevel] = useState("");
  const [typeDescription, setTypeDescription] = useState(null);
  const [gradeLevels, setGradeLevels] = useState([]);
  const [gradeLevelLearners, setGradeLevelLearners] = useState([]);

  // sets the array of possible gradeLevels for report selection
  useEffect(() => {
    setIsLoading(true);
    establishGradeLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // sets the description in the UI based on the report selected
  useEffect(() => {
    if (selectedReportType === "gradelevel-file") {
      setTypeDescription(
        "This report is for printing Gather data for an entire grade level and including each report in the learner's paper records folder. This report will include the latest narrative data for the learner in each course instance within Gather."
      );
    } else if (selectedReportType === "gradelevel-archive") {
      setTypeDescription(
        "This report is for saving an entire grade level's year-long historical Gather data to a digital file, to be stored on some long-term storage area, like a Google Drive."
      );
    } else {
      setTypeDescription("Please select a report type to continue...");
    }
  }, [selectedReportType]);

  const handleReportTypeChange = (e) => {
    clearLearners();
    setSelectedReportType(e.target.value);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGradeLevelChange = (e) => {
    clearLearners();
    setSelectedGradeLevel(e.target.value);
  };

  const clearLearners = () => {
    setGradeLevelLearners([]);
  };

  const generateReport = async () => {
    // activate loading status
    setIsLoading(true);

    // fetch all learners in selected gradeLevel
    const fetchedLearners = await fetchAllLearnersInGradeLevel(
      selectedGradeLevel
    );

    // set state
    setGradeLevelLearners(fetchedLearners);

    // deactivate loading status
    setIsLoading(false);
  };

  const establishGradeLevels = async () => {
    const fetchedLevels = await fetchGradeLevels();
    setGradeLevels(fetchedLevels);

    setIsLoading(false);
  };

  return (
    <Container style={{ marginTop: "11em" }}>
      <Typography variant="h3">Gather Reports Selector</Typography>
      {gradeLevels && gradeLevels.length && (
        <Grid
          item
          container
          direction="column"
          spacing={3}
          style={{ marginTop: "2em" }}
        >
          <Grid item container direction="row" spacing={4}>
            <Grid item>
              <FormControl
                variant="outlined"
                sx={(theme) => ({
                  margin: theme.spacing(1),
                  minWidth: "350px",
                })}
              >
                <InputLabel id="report-select-label">Report Type</InputLabel>
                <Select
                  labelId="report-select-label"
                  id="report-select"
                  value={selectedReportType}
                  onChange={handleReportTypeChange}
                  label="Report Type"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="gradelevel-file">
                    Reports for Learner File
                  </MenuItem>
                  <MenuItem value="gradelevel-archive">
                    Reports for Digital Archive
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                sx={(theme) => ({
                  margin: theme.spacing(1),
                  minWidth: "350px",
                })}
              >
                <InputLabel id="report-select-grade-label">
                  Grade Level
                </InputLabel>
                <Select
                  labelId="report-select-grade-label"
                  id="report-select-grade"
                  value={selectedGradeLevel}
                  onChange={handleGradeLevelChange}
                  label="Grade Level"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {gradeLevels.map((gradeLevel) => {
                    return (
                      <MenuItem value={gradeLevel} key={gradeLevel}>
                        {gradeLevel}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Typography>Description:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{typeDescription}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              item
              container
              direction="row"
              spacing={1}
              sx={{ marginTop: "2em" }}
            >
              <Grid item>
                {selectedReportType && selectedGradeLevel && (
                  <Button variant="contained" onClick={generateReport}>
                    Generate
                  </Button>
                )}
              </Grid>
              <Grid item>
                {gradeLevelLearners && gradeLevelLearners.length > 0 && (
                  <div>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handlePrint();
                        clearLearners();
                      }}
                    >
                      Print Report
                    </Button>
                    <div style={{ display: "none" }}>
                      <AdminReportsContainer
                        printReportComponent={componentRef}
                        gradeLevelLearners={gradeLevelLearners}
                        selectedReportType={selectedReportType}
                        currentSchoolYear={currentSchoolYear}
                        setGradeLevelLearners={setGradeLevelLearners}
                      />
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item>
                <Button variant="contained" component={Link} to="/">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default AdminReportsSelector;
