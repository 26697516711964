import React, { useState, useEffect, createContext } from "react";
import {
  onSnapshot,
  collection,
  where,
  orderBy,
  query,
} from "firebase/firestore";
import { firestore } from "../firebaseInit";
import { currentSchoolYear } from "../config/currentSchoolYear";

export const RatingPropertiesContext = createContext();

export const RatingPropertiesProvider = (props) => {
  const [ratingProperties, setRatingProperties] = useState(null);

  useEffect(() => {
    const properties = [];

    const q = query(
      collection(firestore, "courseRatingProperties"),
      where("schoolYear", "==", currentSchoolYear),
      orderBy("sortOrder", "asc")
    );

    const unsub = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        properties.push(doc.data());
      });

      setRatingProperties(properties);
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <RatingPropertiesContext.Provider
      value={{
        ratingProperties,
      }}
    >
      {props.children}
    </RatingPropertiesContext.Provider>
  );
};
