import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import GradeLevelFileReport from "./GradeLevelFileReport";
import GradeLevelArchiveReport from "./GradeLevelArchiveReport";
import { MessageContext } from "../../../contexts/MessageContext";

const AdminReportsContainer = ({
  gradeLevelLearners,
  printReportComponent,
  selectedReportType,
  currentSchoolYear,
  setGradeLevelLearners,
}) => {
  const { setMessage } = useContext(MessageContext);
  const [learnersToPrint, setLearnersToPrint] = useState([]);

  useEffect(() => {
    if (selectedReportType === "gradelevel-file") {
      formatLearnersForFileReport(gradeLevelLearners);
    } else {
      formatLearnersForArchiveReport(gradeLevelLearners);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatLearnersForFileReport = (learners) => {
    const formattedLearners = _.compact(
      learners.map((learner) => {
        let lastLearnerPortrait = null;

        if (learner.portraits && learner.portraits.length > 0) {
          const publishedPortraits = _.filter(learner.portraits, {
            isPublished: true,
          });

          const orderedPortraits = _.orderBy(
            publishedPortraits,
            ["publishedOn"],
            ["asc"]
          );

          if (orderedPortraits.length >= 1) {
            const capturedPortrait = orderedPortraits.pop();

            lastLearnerPortrait = {
              analysis: capturedPortrait.analysis,
              publishedOn: capturedPortrait.publishedOn,
              teacherFirstName: capturedPortrait.teacherFirstName,
              teacherLastName: capturedPortrait.teacherLastName,
            };
          }
        }

        const sortedSections = _.orderBy(
          learner.sections,
          ["courseName"],
          ["asc"]
        );

        const compiledSections = sortedSections.map((section) => {
          const publishedNarratives = _.filter(section.narratives, {
            isPublished: true,
          });

          const publishedSortedNarratives = _.orderBy(
            publishedNarratives,
            ["publishedOn"],
            ["asc"]
          );

          let lastNarrative =
            publishedSortedNarratives && publishedSortedNarratives.length
              ? publishedSortedNarratives.pop()
              : null;

          if (lastNarrative) {
            const foundTeacher = _.find(section.teachers, {
              teacherId: lastNarrative.teacherId,
            });

            lastNarrative = {
              analysis: lastNarrative.analysis,
              teacherFirstName: foundTeacher.firstName,
              teacherLastName: foundTeacher.lastName,
              publishedOn: lastNarrative.publishedOn,
            };
          }

          return {
            courseName: section.courseName,
            lastNarrative,
            teachers: section.teachers,
          };
        });

        let noNarrativeData = true;

        compiledSections.map((section) => {
          if (section.lastNarrative !== null) {
            noNarrativeData = false;
          }
          return null;
        });

        if (noNarrativeData) {
          return null;
        }

        return {
          firstName: learner.firstName,
          lastName: learner.lastName,
          learnerId: learner.learnerId,
          lastLearnerPortrait,
          sections: compiledSections,
        };
      })
    );

    if (formattedLearners && formattedLearners.length) {
      setLearnersToPrint(_.orderBy(formattedLearners, ["lastName"], ["asc"]));
    } else {
      setGradeLevelLearners([]);
      setMessage(
        "info",
        "There are no published items for this grade level as of yet."
      );
    }
  };

  const formatLearnersForArchiveReport = (learners) => {
    const formattedLearners = _.compact(
      learners.map((learner) => {
        let compiledLearnerPortraits = null;

        if (learner.portraits && learner.portraits.length > 0) {
          const publishedPortraits = _.filter(learner.portraits, {
            isPublished: true,
          });

          compiledLearnerPortraits = _.orderBy(
            publishedPortraits,
            ["publishedOn"],
            ["desc"]
          );
        }

        const sortedSections = _.orderBy(
          learner.sections,
          ["courseName"],
          ["asc"]
        );

        const compiledSections = sortedSections.map((section) => {
          let compiledNarratives = null;

          if (section.narratives && section.narratives.length > 0) {
            const publishedNarratives = _.filter(section.narratives, {
              isPublished: true,
            });

            compiledNarratives = _.orderBy(
              publishedNarratives,
              ["publishedOn"],
              ["desc"]
            );
          }

          if (compiledNarratives && compiledNarratives.length > 0) {
            compiledNarratives = compiledNarratives.map((narrative) => {
              // fetch the teacher name
              const foundTeacher = _.find(section.teachers, {
                teacherId: narrative.teacherId,
              });

              return {
                publishedOn: narrative.publishedOn,
                analysis: narrative.analysis,
                teacherFirstName: foundTeacher.firstName,
                teacherLastName: foundTeacher.lastName,
              };
            });
          }

          return {
            courseName: section.courseName,
            narratives: compiledNarratives,
            teachers: section.teachers,
          };
        });

        // check for no narrative data across all sections, and then exclude from report accordingly

        let noNarratives = true;

        learner &&
          learner.sections &&
          learner.sections.length &&
          learner.sections.map((section) => {
            if (section.narratives && section.narratives.length > 0) {
              noNarratives = false;
            }

            return null;
          });

        if (noNarratives) {
          return null;
        } else {
          return {
            firstName: learner.firstName,
            lastName: learner.lastName,
            learnerId: learner.learnerId,
            learnerPortraits: compiledLearnerPortraits,
            sections: compiledSections,
          };
        }
      })
    );

    if (formattedLearners && formattedLearners.length) {
      setLearnersToPrint(_.orderBy(formattedLearners, ["lastName"], ["asc"]));
    } else {
      setGradeLevelLearners([]);
      setMessage(
        "info",
        "There are no published items for this grade level as of yet."
      );
    }
  };

  return learnersToPrint &&
    learnersToPrint.length > 0 &&
    selectedReportType === "gradelevel-file" ? (
    <GradeLevelFileReport
      learnersToPrint={learnersToPrint}
      currentSchoolYear={currentSchoolYear}
      ref={printReportComponent}
    />
  ) : (
    learnersToPrint && learnersToPrint.length > 0 && (
      <GradeLevelArchiveReport
        learnersToPrint={learnersToPrint}
        currentSchoolYear={currentSchoolYear}
        ref={printReportComponent}
      />
    )
  );
};

export default AdminReportsContainer;
