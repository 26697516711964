import formatDate from "../../../utils/dateFormatter";
import { Typography, Grid, Button } from "@mui/material";

const LatestNarrativeDisplay = ({
  latestNarrativeData,
  setLearnerNarrativesOpen,
}) => (
  <Grid item container direction="column">
    <Grid item>
      <Typography variant="body2">{`Created On ${formatDate(
        latestNarrativeData.latestNarrativeCreatedOn
      )}`}</Typography>
    </Grid>
    <Grid item>
      {latestNarrativeData.status === "Draft" ? (
        <Typography variant="body2">{`(${latestNarrativeData.status})`}</Typography>
      ) : (
        <Typography variant="body2">{`(${
          latestNarrativeData.status
        } on ${formatDate(
          latestNarrativeData.latestNarrativePublishedOn
        )})`}</Typography>
      )}
    </Grid>
    <Grid item>
      <Button
        variant="outlined"
        size="small"
        sx={{ marginTop: "10px" }}
        onClick={() => setLearnerNarrativesOpen(true)}
      >
        Show Narratives
      </Button>
    </Grid>
  </Grid>
);

export default LatestNarrativeDisplay;
