import React, { useContext } from "react";
import { Button, Avatar, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "../../contexts/AuthContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import { MessageContext } from "../../contexts/MessageContext";
import googleSignInIcon from "../../assets/googleSignInIcon.png";

const GoogleAuthButton = () => {
  const { googleSignIn } = useAuth();
  const theme = useTheme();
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);

  const handleGoogleSignIn = async () => {
    // set app loading state to true
    setIsLoading(true);

    // perform signin operation
    try {
      await googleSignIn();

      // set app loading state to false
      setIsLoading(false);
    } catch (err) {
      // set app loading state to false
      setIsLoading(false);

      return setMessage(
        "error",
        "There was an error signing in with Google: " + err,
        6000
      );
    }
  };

  return (
    <Button
      variant="contained"
      startIcon={
        false ? (
          <CircularProgress size={24} />
        ) : (
          <Avatar src={googleSignInIcon} />
        )
      }
      sx={{
        minWidth: "10em",
        backgroundColor: theme.palette.common.mediumColor,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.common.googleBlue,
        },
        borderRadius: "50px",
        marginLeft: "30px",
        marginRight: "20px",
      }}
      onClick={() => {
        handleGoogleSignIn();
      }}
    >
      {"Sign In with Google"}
    </Button>
  );
};

export default GoogleAuthButton;
