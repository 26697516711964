import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import useCourseProgressSelector from "./hooks/useCourseProgressSelector";
import { RatingPropertiesContext } from "../../../contexts/RatingPropertiesContext";

const CourseProgressSelector = ({ learner }) => {
  const [currentRating, setCurrentRating] = useState(null);
  const { ratingProperties } = useContext(RatingPropertiesContext);

  const { fetchCurrentRating, setCourseRating } = useCourseProgressSelector();

  useEffect(() => {
    const foundRating = fetchCurrentRating(learner);

    setCurrentRating(foundRating);
  }, [fetchCurrentRating, learner]);

  const handleProgressChange = (e) => {
    setCourseRating(e.target.value, learner);
  };

  return currentRating ? (
    <FormControl variant="standard" sx={{ width: 120 }}>
      <InputLabel id="progress-select-small-label">Expectations</InputLabel>
      <Select
        labelId="progress-select-small"
        id="demo-select-small"
        value={currentRating.rating}
        label="Expectations"
        onChange={handleProgressChange}
        size="small"
      >
        {ratingProperties.map((ratingProperty, index) => (
          <MenuItem key={ratingProperty.code} value={ratingProperty.rating}>
            {ratingProperty.index === 0 ? (
              <em>{ratingProperty.rating}</em>
            ) : (
              ratingProperty.rating
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default CourseProgressSelector;
