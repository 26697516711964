import { useEffect, useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  TextField,
} from "@mui/material";
import { SectionContext } from "../../../contexts/SectionContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import GlowButton from "../../custom/GlowButton";
import useCreateDeletePortrait from "./hooks/useCreateDeletePortrait";

const CreateNewPortraitDialog = ({
  newPortraitOpen,
  handleNewPortraitClose,
  learner,
}) => {
  // const { currentSectionId } = useContext(SectionContext);
  // const { setIsLoading } = useContext(LoadingContext);
  const { createNewPortrait } = useCreateDeletePortrait();

  const [portraitText, setPortraitText] = useState("");
  const [portraitTextError, setPortraitTextError] = useState("");

  const clearPortraitDialog = () => {
    setPortraitText("");
    setPortraitTextError("");
    handleNewPortraitClose();
  };

  const handlePortraitChange = (e) => {
    setPortraitText(e.target.value);
  };

  const handlePortraitSave = async (isPublished) => {
    if (!portraitText || !portraitText.length || portraitText.length === 0) {
      return setPortraitTextError("The portrait cannot be blank.");
    }

    if (portraitTextError.length > 0) {
      setPortraitTextError("");
    }

    const basePortraitText = portraitText.trim();

    const result = await createNewPortrait(
      basePortraitText,
      isPublished,
      learner
    );

    if (result.status === "success") {
      clearPortraitDialog();
    }
  };

  return (
    <Dialog open={newPortraitOpen} fullWidth maxWidth="xl">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>
          {`A New Portrait for ${learner.firstName}`}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid item container direction="column" sx={{ marginBottom: "30px" }}>
          <Grid item>
            <Typography
              variant="body1"
              sx={{ fontSize: 20, marginBottom: "10px" }}
            >
              Learner Portraits are your opportunity to speak to the child as a
              whole, outside of a formal content area.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" sx={{ fontSize: 20 }}>
              For example, their development in terms of the District's Learner
              Competencies can serve as a decent centerpiece for the purposes of
              authoring a Learner Portrait.
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "20px" }}>
          <TextField
            multiline
            error={portraitTextError.length > 0}
            fullWidth
            value={portraitText}
            id="outlined-portrait-edit"
            label="Learner Portrait"
            onChange={(e) => handlePortraitChange(e)}
            helperText={portraitTextError}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <GlowButton
          variant="outlined"
          onClick={() => handlePortraitSave(false)}
        >
          Create Draft Portrait
        </GlowButton>
        <GlowButton variant="outlined" onClick={() => handlePortraitSave(true)}>
          Create Published Portrait
        </GlowButton>
        <GlowButton
          variant="outlined"
          onClick={() => {
            clearPortraitDialog();
          }}
        >
          Cancel
        </GlowButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewPortraitDialog;
