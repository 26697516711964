import React, { useState } from "react";
import { useContext } from "react";
import {
  Button,
  Grid,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { SectionContext } from "../../contexts/SectionContext";
import { LoadingContext } from "../../contexts/LoadingContext";
import SignInButtonMenu from "./SignInButtonMenu";
import LogoutIcon from "@mui/icons-material/Logout";
import SummarizeIcon from "@mui/icons-material/Summarize";

const NavButtonBar = () => {
  const { googleSignOut, currentUser } = useAuth();
  const { setCurrentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleAvatarClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    // set app loading state to true
    setIsLoading(true);

    // perform signout operation
    await googleSignOut();

    // clear section context
    setCurrentSectionId(null);

    // set app loading state to true
    setIsLoading(false);
  };

  return (
    <Grid item>
      <Grid item container direction="row" alignItems="center" spacing={2}>
        {currentUser ? (
          <Grid item sx={{ marginLeft: "10px" }}>
            <Grid item lg={1} md={2} xs={3}>
              <Tooltip title="Account Menu">
                <IconButton
                  onClick={handleAvatarClick}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    alt="Avatar"
                    src={currentUser.photoURL}
                    sx={{ width: 60, height: 60 }}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <SignInButtonMenu />
          </Grid>
        )}
      </Grid>

      {/* User avatar menu */}
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleAvatarClose}
        onClick={handleAvatarClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {currentUser && currentUser.role === "admin" && (
          <MenuItem component={Link} to="/admin-reporting-selector">
            <ListItemIcon>
              <SummarizeIcon fontSize="small" />
            </ListItemIcon>
            Reports
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default NavButtonBar;
