import { Container, Typography, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

const SupportPage = () => {
  return (
    <Container style={{ marginTop: "10em" }}>
      <Grid item container direction="column">
        <Grid item>
          <Typography variant="h2">Getting Help with Gather</Typography>
        </Grid>
        <Grid item style={{ marginTop: "2em", marginBottom: "1em" }}>
          <Typography variant="body1">
            There are several reasons that you may be in need of assistance with
            Gather. We have a very concise line of communication for providing
            support.
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
          <Typography variant="body1">
            In the event that you cannot register your email with Gather, you
            will likely need to speak with a secretary in the office at your
            child's school. The phone number for the Craddock Main Office is
            330-562-3175, and the phone number for the Miller Main Office is
            330-562-6199.
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
          <Typography variant="body1">
            Staff members needing assistance should create a case within the
            District's Helpdesk system.
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
          <Typography variant="body1">Thank you.</Typography>
        </Grid>
        <Grid
          item
          align="center"
          style={{ marginTop: "1em", marginBottom: "1em" }}
        >
          <Button variant="outlined" component={Link} to="/">
            Back to Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SupportPage;
