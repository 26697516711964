import React from "react";
import {
  Typography,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/gatherLogoColor.png";
import homePic01 from "../assets/homePic01.jpg";
import homePic02 from "../assets/homePic02.jpg";
import homePic03 from "../assets/homePic03.jpg";

const cards = [
  {
    key: 1,
    image: homePic01,
    heading: "Clarity",
    content:
      "We've evolved past grades and points, focusing on narratives that capture the nuance of a young learner's development.",
  },
  {
    key: 2,
    image: homePic02,
    heading: "Honesty",
    content:
      "We provide a high resolution view of how a learner is adapting to every aspect of their school experience.",
  },
  {
    key: 3,
    image: homePic03,
    heading: "Transparency",
    content:
      "Guardians have instant access to the published analysis from their child's teacher, all day, and everyday.",
  },
];

const HomePage = (props) => {
  const theme = useTheme();

  const tight = useMediaQuery(theme.breakpoints.down("md"));
  const tighter = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Container style={{ marginTop: "8em" }}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Box
              component="img"
              sx={{
                maxHeight: { xs: "9em", md: "12em", lg: "15em" },
                marginTop: "40px",
              }}
              alt="Gather Logo"
              src={logo}
            />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: {
                  lg: "4em",
                  md: "3em",
                  xs: "2em",
                },
                lineHeight: "1.1",
              }}
              align="center"
            >
              Revolutionizing school culture through human-centered approaches
              to learning and reporting.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container
        sx={{
          paddingTop: theme.spacing(8),
          paddingBottom: theme.spacing(8),
          marginTop: "3em",
        }}
        maxWidth="md"
      >
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.key} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: theme.palette.common.panelColor,
                }}
              >
                <CardMedia
                  sx={{ paddingTop: "56.25%" }}
                  image={card.image}
                  title="Image title"
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.heading}
                  </Typography>
                  <Typography>{card.content}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
