import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Typography, Grid, Button } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTheme } from "@mui/material/styles";
import GoogleAuthButton from "./GoogleAuthButton";

const StaffSigninPage = () => {
  const theme = useTheme();
  let navigate = useNavigate();

  return (
    <Grid
      item
      container
      direction="column"
      sx={{
        marginTop: "12em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid item>
        <Avatar
          sx={{
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          <LockOutlinedIcon />
        </Avatar>
      </Grid>
      <Grid item>
        <Typography component="h1" variant="h4">
          Staff Sign In
        </Typography>
      </Grid>
      <Grid item align="center">
        <Typography
          component="h2"
          variant="h6"
          sx={{
            marginTop: "2em",
            marginBottom: "2em",
            color: theme.palette.common.mediumColor,
          }}
        >
          Please sign in with your school-provided Google account.
        </Typography>
      </Grid>
      <Grid item>
        <GoogleAuthButton />
      </Grid>
      <Grid item style={{ marginTop: "2em" }} xs>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate("/")}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
};

export default StaffSigninPage;
