import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Grid, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";

const GuardianLearnerSelector = ({ currentLearner, setCurrentLearnerId }) => {
  const { currentUser } = useAuth();
  const [learnerListing, setLearnerListing] = useState(null);

  useEffect(() => {
    const listing = currentUser.learners.map((learner) => {
      return {
        learnerId: learner.learnerId,
        firstName: learner.firstName,
        lastName: learner.lastName,
      };
    });
    setLearnerListing(_.orderBy(listing, ["learnerId"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleLearnerChange = (e) => {
    setCurrentLearnerId(e.target.value);
  };

  return (
    learnerListing &&
    learnerListing.length > 1 && (
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel id="select-learner-label">Learner</InputLabel>
            <Select
              label="Learner"
              labelId="select-learner-label"
              id="select-learner"
              value={currentLearner.learnerId}
              onChange={handleLearnerChange}
            >
              {learnerListing.map((learner) => (
                <MenuItem value={learner.learnerId} key={learner.learnerId}>
                  {`${learner.firstName} ${learner.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  );
};

export default GuardianLearnerSelector;
