import { CssBaseline } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import AuthProvider from "./contexts/AuthContext";
import { MessageProvider } from "./contexts/MessageContext";
import { SectionProvider } from "./contexts/SectionContext";
import { LearnerProvider } from "./contexts/LearnerContext";
import { LoadingProvider } from "./contexts/LoadingContext";
import { RatingPropertiesProvider } from "./contexts/RatingPropertiesContext";
import { NarrativeStartersProvider } from "./contexts/NarrativeStartersContext";
import App from "./App";

const appBackground = "#ebebeb";
const paperColor = "#fafafa";
const gatherDark = "#042c4c";
const gatherMedium = "#33837b";
const gatherGlow = "#1bf2f2";
const googleBlue = "#4285f4";

const theme = createTheme({
  palette: {
    common: {
      mediumColor: gatherMedium,
      darkColor: gatherDark,
      glowColor: gatherGlow,
      panelColor: paperColor,
      googleBlue: googleBlue,
    },
    primary: {
      main: gatherDark,
    },
    secondary: {
      main: gatherMedium,
    },
    background: {
      default: appBackground,
    },
  },
  typography: {
    tab: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
    body1: {
      fontFamily: "Roboto",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem",
    },
    h4: {
      fontFamily: "Roboto",
      fontWeight: 900,
      fontSize: "2rem",
      color: gatherDark,
    },
    h5: {
      fontFamily: "Roboto",
      fontWeight: 900,
      fontSize: "1.5rem",
      color: gatherMedium,
    },
    learnerLink: {
      color: gatherDark,
      fontWeight: 900,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <MessageProvider>
        <LoadingProvider>
          <AuthProvider>
            <SectionProvider>
              <LearnerProvider>
                <RatingPropertiesProvider>
                  <NarrativeStartersProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </NarrativeStartersProvider>
                </RatingPropertiesProvider>
              </LearnerProvider>
            </SectionProvider>
          </AuthProvider>
        </LoadingProvider>
      </MessageProvider>
    </ThemeProvider>
  </>
);
