import { useEffect, useContext, useState } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  TextField,
} from "@mui/material";
import { SectionContext } from "../../../contexts/SectionContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import GlowButton from "../../custom/GlowButton";
import useCreateDeleteNarrative from "./hooks/useCreateDeleteNarrative";

const CreateNewNarrativeDialog = ({
  newNarrativeOpen,
  handleNewNarrativeClose,
  learner,
}) => {
  const { currentSectionId } = useContext(SectionContext);
  const { isLoading } = useContext(LoadingContext);
  const { fetchOpeningStatement, createNewNarrative } =
    useCreateDeleteNarrative();

  const [openingStatement, setOpeningStatement] = useState(null);
  const [analysis, setAnalysis] = useState("");
  const [analysisError, setAnalysisError] = useState("");

  useEffect(() => {
    const fetchedStatement = fetchOpeningStatement(learner);
    setOpeningStatement(fetchedStatement);
  });

  const clearAnalysisDialog = () => {
    setAnalysis("");
    setAnalysisError("");
    handleNewNarrativeClose();
  };

  const handleAnalysisChange = (e) => {
    setAnalysis(e.target.value);
  };

  const handleAnalysisSave = async (isPublished) => {
    if (!analysis || !analysis.length || analysis.length === 0) {
      return setAnalysisError("The analysis cannot be blank.");
    }

    if (analysisError.length > 0) {
      setAnalysisError("");
    }

    const baseAnalysis = analysis.trim();

    const result = await createNewNarrative(
      openingStatement,
      baseAnalysis,
      isPublished,
      learner
    );

    if (result.status === "success") {
      clearAnalysisDialog();
    }
  };

  return (
    <Dialog open={newNarrativeOpen} fullWidth maxWidth="xl">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>
          {`A New Narrative for ${learner.firstName}`}
        </Typography>
      </DialogTitle>
      {openingStatement && (
        <>
          <DialogContent>
            <Grid item container direction="column">
              <Grid item sx={{ marginBottom: "10px" }}>
                <Typography variant="body1" sx={{ fontSize: 20 }}>
                  You are creating a new narrative. The first line of your
                  narrative will read as follows:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 20, mt: "10px", ml: "10px" }}
                >
                  {`"${openingStatement}"`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ marginTop: "20px" }}>
              <TextField
                multiline
                error={analysisError.length > 0}
                fullWidth
                value={analysis}
                id="outlined-analysis-edit"
                label="Narrative Analysis"
                onChange={(e) => handleAnalysisChange(e)}
                helperText={analysisError}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <GlowButton
              variant="outlined"
              onClick={() => handleAnalysisSave(false)}
            >
              Create Draft Narrative
            </GlowButton>
            <GlowButton
              variant="outlined"
              onClick={() => handleAnalysisSave(true)}
            >
              Create Published Narrative
            </GlowButton>
            <GlowButton
              variant="outlined"
              onClick={() => {
                clearAnalysisDialog();
              }}
            >
              Cancel
            </GlowButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CreateNewNarrativeDialog;
