import _ from "lodash";
import { useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import { doc, setDoc } from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";

const useNarrativeStartersDialog = () => {
  const { currentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);

  const starterConfigOptions = [
    {
      value: 0,
      description:
        "No starters means that you will be writing custom narratives for each individual learner.",
    },
    {
      value: 1,
      description:
        "One narrative starter is typically used when each learner is receiving the same general comment regarding the course.",
    },
    {
      value: 2,
      description:
        "You can establish two levels of pre-written narrative feedback.",
    },
    {
      value: 3,
      description:
        "You can establish three levels of pre-written narrative feedback.",
    },
    {
      value: 4,
      description:
        "You can establish four levels of pre-written narrative feedback.",
    },
    {
      value: 5,
      description:
        "You can establish five levels of pre-written narrative feedback.",
    },
  ];

  const saveNarrativeStarters = async (narrativeStarters) => {
    setIsLoading(true);

    try {
      const starterRef = doc(
        firestore,
        "narrativeStarters",
        `${currentSectionId}-${currentSchoolYear}`
      );
      await setDoc(starterRef, { narrativeStarters });

      setIsLoading(false);

      setMessage("success", "Narrative starters have been saved successfully.");

      return { status: "success" };
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save these narrative starters: ${err.message}`
      );

      setIsLoading(false);

      return { status: "error" };
    }
  };

  return {
    saveNarrativeStarters,
    starterConfigOptions,
  };
};

export default useNarrativeStartersDialog;
