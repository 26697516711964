import React, { useRef, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Grid,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "../../contexts/AuthContext";
import { MessageContext } from "../../contexts/MessageContext";
import { LoadingContext } from "../../contexts/LoadingContext";

const GuardianSignupPage = () => {
  const theme = useTheme();
  const { signupUserWithEmailAndPassword } = useAuth();
  const { setMessage } = useContext(MessageContext);
  const { setIsLoading } = useContext(LoadingContext);
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const handleSignUp = async (e) => {
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setMessage(
        "error",
        "The provided passwords do not match...",
        5000
      );
    }

    if (
      passwordRef.current.value.length === 0 ||
      passwordConfirmRef.current.value.length === 0 ||
      emailRef.current.value.length === 0
    ) {
      return setMessage(
        "error",
        "Email, password, and a password confirmation are all required for sign up...",
        5000
      );
    }

    try {
      // set loading status to true
      setIsLoading(true);

      await signupUserWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );

      // set loading status to false
      setIsLoading(false);
    } catch (err) {
      // set loading status to false
      setIsLoading(false);

      return setMessage(
        "error",
        `Failed to create an account... ${err} `,
        5000
      );
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "200px" }}>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h4">
            Guardian Sign Up
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="body1">
            Please provide an email that is registered with Aurora City Schools.
            We check this during the sign up process for security purposes.
          </Typography>
        </Grid>
        <Grid item sx={{ width: "100%", marginTop: "2em" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            inputRef={emailRef}
            autoFocus
          />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            inputRef={passwordRef}
          />
        </Grid>
        <Grid item sx={{ width: "100%" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password-confirm"
            label="Confirm Password"
            type="password"
            id="password-confirm"
            inputRef={passwordConfirmRef}
          />
        </Grid>
        <Grid item sx={{ marginTop: "1.5em", width: "100%" }}>
          <Button
            onClick={handleSignUp}
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign Up with Email
          </Button>
        </Grid>
        <Grid item>
          <Link component={RouterLink} to="/" variant="body2">
            Cancel
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GuardianSignupPage;
