import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  ButtonGroup,
  TextField,
} from "@mui/material";
import useStarterSelectedDialog from "./hooks/useStarterSelectedDialog";
import GlowButton from "../../custom/GlowButton";
import { useEffect, useState } from "react";

const StarterSelectedDialog = ({
  starterSelectedOpen,
  handleStarterSelectedClose,
  selectedStarter,
  learner,
  numOfStarters,
}) => {
  const { saveNarrativeForLearner, saveNarrativeForAllLearners } =
    useStarterSelectedDialog();

  // starter text can be edited prior to instantiating the narrative
  // instance, so...
  const [editedStarterText, setEditedStarterText] = useState("");

  // conditionally setting the starter text each time the
  // dialog is opened
  useEffect(() => {
    if (starterSelectedOpen) {
      setEditedStarterText(selectedStarter.starterText);
    }
  }, [starterSelectedOpen]);

  return (
    <Dialog open={starterSelectedOpen} maxWidth="lg">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>Starter Selected</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <Grid item>
            <Typography sx={{ fontSize: 25 }}>
              Warning: This process will create an actual narrative for a
              learner. If this is not your intention, please cancel this
              operation.
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: "20px" }}>
            <Typography variant="body2" sx={{ fontSize: 18 }}>
              {`You've selected the folowing narrative starter, which you can edit here before creating:`}
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: "10px" }}>
            <Grid item sx={{ marginTop: "10px" }}>
              <TextField
                value={editedStarterText}
                onChange={(e) => setEditedStarterText(e.target.value)}
                fullWidth
                multiline
                rows={4}
                inputProps={{
                  style: { backgroundColor: "white", color: "black" },
                }}
              />
            </Grid>
          </Grid>
          <Grid item sx={{ marginTop: "10px" }}>
            <Typography variant="body2" sx={{ fontSize: 18 }}>
              {`Please choose from the following actions to continue:`}
            </Typography>
          </Grid>

          <Grid item align="center" sx={{ marginTop: "30px" }}>
            <ButtonGroup
              orientation="vertical"
              aria-label="vertical contained button group"
              variant="text"
            >
              <GlowButton
                onClick={() => {
                  saveNarrativeForLearner(
                    { ...selectedStarter, starterText: editedStarterText },
                    learner
                  );
                  handleStarterSelectedClose();
                }}
              >
                Create a Draft Narrative
              </GlowButton>
              <GlowButton
                onClick={async () => {
                  await saveNarrativeForLearner(
                    { ...selectedStarter, starterText: editedStarterText },
                    learner,
                    "published"
                  );
                  handleStarterSelectedClose();
                }}
              >
                Create a Published Narrative
              </GlowButton>
              {numOfStarters === 1 && (
                <>
                  <GlowButton
                    onClick={() => {
                      saveNarrativeForAllLearners({
                        ...selectedStarter,
                        starterText: editedStarterText,
                      });
                      handleStarterSelectedClose();
                    }}
                  >
                    Create a Draft Narrative for All Learners
                  </GlowButton>
                  <GlowButton
                    onClick={() => {
                      saveNarrativeForAllLearners(
                        { ...selectedStarter, starterText: editedStarterText },
                        "published"
                      );
                      handleStarterSelectedClose();
                    }}
                  >
                    Create a Published Narrative for All Learners
                  </GlowButton>
                </>
              )}
              <GlowButton onClick={handleStarterSelectedClose}>
                Cancel
              </GlowButton>
            </ButtonGroup>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default StarterSelectedDialog;
