import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Tooltip,
} from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import StarterSelectedDialog from "./StarterSelectedDialog";

const NarrativeStarterSelector = ({ learner, currentStarters }) => {
  const [starterSelections, setStarterSelections] = useState(currentStarters);
  const [selectedStarter, setSelectedStarter] = useState(currentStarters[0]);
  const [selectDisabled, setSelectDisabled] = useState(true);
  const [starterSelectedOpen, setStarterSelectedOpen] = useState(false);

  // determine if the selector should be active
  useEffect(() => {
    if (
      learner.courseRating &&
      learner.courseRating !== "Not Rated" &&
      currentStarters &&
      currentStarters.length > 0
    ) {
      setSelectDisabled(false);
    }
  }, [currentStarters]);

  // inject the first option on the narrative starters selections
  useEffect(() => {
    const firstSelection = { sortOrder: 0, starterText: "Ready to Create" };
    const selections = [firstSelection];

    currentStarters.map((starter) =>
      selections.push({ ...starter, sortOrder: starter.sortOrder + 1 })
    );

    setStarterSelections(selections);
    setSelectedStarter(firstSelection);
  }, [currentStarters]);

  const handleStarterChange = (e) => {
    // set the selected starter
    const selected = _.find(starterSelections, { starterText: e.target.value });
    setSelectedStarter(selected);

    // open the starter selected dialog and provide options
    // relevant to the status of the course

    setStarterSelectedOpen(true);
  };

  const handleStarterSelectedClose = () => {
    setSelectedStarter(starterSelections[0]);
    setStarterSelectedOpen(false);
  };

  return currentStarters ? (
    starterSelections &&
      (selectDisabled ? (
        <Tooltip
          title="Narrative starters must be configured and the learner's course rating must be set first."
          placement="top"
        >
          <BlockIcon fontSize="large" />
        </Tooltip>
      ) : (
        <>
          <FormControl variant="standard" sx={{ width: 170 }}>
            <InputLabel id="starter-select-small-label">Starter</InputLabel>
            <Select
              labelId="starter-select-small"
              id="demo-select-small"
              value={selectedStarter.starterText}
              label="Starter"
              onChange={handleStarterChange}
              size="small"
              disabled={selectDisabled}
            >
              {starterSelections.map((starter) => (
                <MenuItem
                  sx={{ maxWidth: "400px" }}
                  key={starter.sortOrder}
                  value={starter.starterText}
                >
                  {`${starter.starterText}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <StarterSelectedDialog
            starterSelectedOpen={starterSelectedOpen}
            handleStarterSelectedClose={handleStarterSelectedClose}
            selectedStarter={selectedStarter}
            learner={learner}
            numOfStarters={currentStarters.length}
          />
        </>
      ))
  ) : (
    <Typography>Loading...</Typography>
  );
};

export default NarrativeStarterSelector;
