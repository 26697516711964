import _ from "lodash";
import { useContext, useState } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  Timestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { useAuth } from "../../../../contexts/AuthContext";

const usePortraitAnalysisEditing = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);
  const { currentSectionId } = useContext(SectionContext);
  const { currentUser } = useAuth();

  const updatePortrait = async (updatedPortraitData, operationType = null) => {
    // need to set timestamp for relevant date fields
    const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

    // returns the published on value if we have an operation affecting
    // that value
    const getPublishedOnValue = () => {
      if (
        operationType &&
        operationType === "publishToggle" &&
        updatedPortraitData.isPublished === true
      )
        return currentTimestamp;
      else if (
        operationType &&
        operationType === "publishToggle" &&
        updatedPortraitData.isPublished === false
      ) {
        return null;
      } else return updatedPortraitData.publishedOn;
    };

    setIsLoading(true);

    try {
      // toggling the published status does not equate to an edit, so...
      const updatedPortrait = {
        analysis: updatedPortraitData.analysis,
        createdOn: updatedPortraitData.createdOn,
        isPublished: updatedPortraitData.isPublished,
        learnerId: updatedPortraitData.learnerId,
        schoolYear: updatedPortraitData.schoolYear,
        sectionId: updatedPortraitData.sectionId,
        teacherId: updatedPortraitData.teacherId,
        teacherLastName: updatedPortraitData.teacherLastName,
        teacherFirstName: updatedPortraitData.teacherFirstName,
        lastEditedOn:
          operationType && operationType === "publishToggle"
            ? updatedPortraitData.lastEditedOn
            : currentTimestamp,
        publishedOn: getPublishedOnValue(),
      };

      // setup vars for updating latest narrative metadata, if needed
      let isLatestPortrait = false;
      let originalTeacherSectionInstance = null;
      let updatedTeacherSectionInstance = null;

      // check to see if this is the latest created portrait for the learner
      // check in the teacher section first
      const foundTeacherSection = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      const foundLearner = _.find(foundTeacherSection.roster, {
        learnerId: updatedPortrait.learnerId,
      });

      if (foundLearner.latestPortraitId === updatedPortraitData.id) {
        // this is the learner's latest portrait, so...
        isLatestPortrait = true;
      }

      const updatedPortraitDocRef = doc(
        firestore,
        "portraits",
        updatedPortraitData.id
      );

      const learnerDocRef = doc(
        firestore,
        "users",
        `${updatedPortrait.learnerId}-${currentSchoolYear}`
      );

      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // fetch the learner doc so that we have the original portraits array
      const fetchedLearner = await getDoc(learnerDocRef);

      const learnerData = fetchedLearner.data();

      const updatedLearnerPortrait = {
        analysis: updatedPortrait.analysis,
        createdOn: updatedPortrait.createdOn,
        isPublished: updatedPortrait.isPublished,
        lastEditedOn: updatedPortrait.lastEditedOn,
        learnerId: updatedPortrait.learnerId,
        publishedOn: getPublishedOnValue(),
        schoolYear: updatedPortrait.schoolYear,
        sectionId: updatedPortrait.sectionId,
        teacherId: updatedPortrait.teacherId,
        teacherLastName: updatedPortrait.teacherLastName,
        teacherFirstName: updatedPortrait.teacherFirstName,
        rootPortraitId: updatedPortraitData.id,
      };

      // isolate the original learner's portrait object, to be removed
      const originalLearnerPortraitObject = _.find(learnerData.portraits, {
        rootPortraitId: updatedPortraitData.id,
      });

      // provide an updated learner portrait object
      const updatedLearnerPortraitObject = updatedLearnerPortrait;

      // if we're dealing with a latest created portrait, we will have
      // to update the teacher's roster object
      if (isLatestPortrait) {
        // isolate the original teacher section roster
        originalTeacherSectionInstance = _.find(currentUser.sections, {
          sectionId: currentSectionId,
        });

        updatedTeacherSectionInstance = {
          ...originalTeacherSectionInstance,
          roster: originalTeacherSectionInstance.roster.map(
            (learnerInstance) => {
              if (learnerInstance.learnerId === updatedPortrait.learnerId) {
                return {
                  ...learnerInstance,
                  latestPortraitLastEditedOn: updatedPortrait.lastEditedOn,
                  latestPortraitPublishedOn: getPublishedOnValue(),
                };
              }
              return learnerInstance;
            }
          ),
        };
      }
      await runTransaction(firestore, async (transaction) => {
        // update the portrait doc in the portrait collection
        transaction.update(updatedPortraitDocRef, updatedPortrait);

        // remove the original portrait item from the learner's portraits array
        transaction.update(learnerDocRef, {
          portraits: arrayRemove(originalLearnerPortraitObject),
        });

        // add the updated portrait object back
        transaction.update(learnerDocRef, {
          portraits: arrayUnion(updatedLearnerPortraitObject),
        });

        // only enact the following transactions if needed
        if (isLatestPortrait) {
          // remove the original roster item from the teacher's roster array
          transaction.update(teacherDocRef, {
            sections: arrayRemove(originalTeacherSectionInstance),
          });

          // add the updated roster item back
          transaction.update(teacherDocRef, {
            sections: arrayUnion(updatedTeacherSectionInstance),
          });
        }
      });

      setIsLoading(false);

      setMessage("success", "The portrait has been updated successfully.");
    } catch (err) {
      setIsLoading(false);

      setMessage(
        "error",
        "There was an error updating the portrait: " + err.message,
        7000
      );
    }
  };

  return {
    updatePortrait,
  };
};

export default usePortraitAnalysisEditing;
