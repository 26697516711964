import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Badge,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CourseRatingChip from "./CourseRatingChip";
import { LearnerContext } from "../../../contexts/LearnerContext";

const GradeLevelSummaryPanel = ({ gradeLevelData }) => {
  const { setCurrentLearnerId } = useContext(LearnerContext);
  const [open, setOpen] = useState(false);
  const [learnerListing, setLearnerListing] = useState([]);
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpen = (learners) => {
    setLearnerListing(learners);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Grid
        item
        container
        direction="column"
        style={{ marginTop: "2em", marginBottom: "2em" }}
      >
        <Dialog
          style={{ minWidth: "300px" }}
          open={open}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="listing-dialog-title"
          aria-describedby="listing-dialog-description"
        >
          <DialogTitle id="listing-dialog-title">Learner Listing</DialogTitle>
          <DialogContent dividers>
            <List aria-label="listed-learners">
              {learnerListing &&
                learnerListing.map((learner) => (
                  <ListItem
                    key={learner.learnerId}
                    button
                    onClick={() => setCurrentLearnerId(learner.learnerId)}
                  >
                    <ListItemText
                      primary={`${learner.firstName} ${learner.lastName}`}
                    />
                  </ListItem>
                ))}
            </List>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Grid item style={{ marginTop: "1em", marginBottom: "2em" }}>
          <Typography variant="h4">{`Grade: ${gradeLevelData.gradeLevel}`}</Typography>
        </Grid>
        <Grid container spacing={2}>
          {gradeLevelData.courseData.map((course) => {
            return (
              <Grid item key={course.courseName} sm>
                <Card sx={{ width: "240px" }}>
                  <CardContent>
                    <Typography sx={{ fontSize: "20px" }} gutterBottom>
                      {course.courseName}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Grid item container direction="column">
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ marginBottom: "1em" }}
                      >
                        <Grid item>
                          <Typography>Rating</Typography>
                        </Grid>
                        <Grid item>
                          <Typography>Frequency</Typography>
                        </Grid>
                      </Grid>
                      {course.courseRatings.map((ratingData) => (
                        <Grid
                          item
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                          key={ratingData.code}
                          style={{
                            marginBottom: "0.25em",
                            marginTop: "0.25em",
                          }}
                        >
                          <Grid item style={{ marginRight: "60px" }}>
                            <CourseRatingChip
                              title={ratingData.title}
                              color={ratingData.color}
                              rating={ratingData.rating}
                              tooltipPos="left"
                            />
                          </Grid>
                          <Grid item>
                            <div
                              onClick={() =>
                                handleClickOpen(ratingData.learners)
                              }
                            >
                              <IconButton aria-label="learner-frequency">
                                <Badge
                                  badgeContent={
                                    ratingData.learners.length
                                      ? ratingData.learners.length
                                      : "0"
                                  }
                                  color="secondary"
                                  sx={(theme) => ({
                                    right: -3,
                                    top: 13,
                                    border: `2px solid ${theme.palette.background.paper}`,
                                    padding: "0 4px",
                                  })}
                                >
                                  <PeopleAltIcon />
                                </Badge>
                              </IconButton>
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default GradeLevelSummaryPanel;
