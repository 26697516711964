import { Grid, Typography, TextField, IconButton } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const NarrativeQtySelector = ({
  starterConfig,
  handleIncreaseStarters,
  handleDecreaseStarters,
}) => {
  const theme = useTheme();

  const QtyChangeButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.darkColor,
    backgroundColor: "lightgray",
    "&:hover": {
      backgroundColor: theme.palette.common.glowColor,
    },
  }));

  return (
    <>
      <Grid item align="center" sx={{ marginTop: "20px" }}>
        <Typography>How many narrative starters do you wish to use?</Typography>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
        sx={{ marginBottom: "10px" }}
      >
        <Grid item>
          <QtyChangeButton onClick={() => handleDecreaseStarters()}>
            <RemoveIcon />
          </QtyChangeButton>
        </Grid>
        <Grid item>
          <TextField
            size="small"
            disabled={true}
            id="starter-quantity"
            variant="outlined"
            value={starterConfig.value}
            sx={{ width: "40px" }}
          />
        </Grid>
        <Grid item>
          <QtyChangeButton onClick={() => handleIncreaseStarters()}>
            <AddIcon />
          </QtyChangeButton>
        </Grid>
      </Grid>
      <Grid item align="center">
        <Typography variant="body2">{starterConfig.description}</Typography>
      </Grid>
    </>
  );
};

export default NarrativeQtySelector;
