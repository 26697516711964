import formatDate from "../../../utils/dateFormatter";
import { Typography, Grid, Button } from "@mui/material";

const LatestPortraitDisplay = ({
  latestPortraitData,
  setLearnerPortraitsOpen,
}) => (
  <Grid item container direction="column">
    <Grid item>
      <Typography variant="body2">{`Created On ${formatDate(
        latestPortraitData.latestPortraitCreatedOn
      )}`}</Typography>
    </Grid>
    <Grid item>
      {latestPortraitData.status === "Draft" ? (
        <Typography variant="body2">{`(${latestPortraitData.status})`}</Typography>
      ) : (
        <Typography variant="body2">{`(${
          latestPortraitData.status
        } on ${formatDate(
          latestPortraitData.latestPortraitPublishedOn
        )})`}</Typography>
      )}
    </Grid>
    <Grid item>
      <Button
        variant="outlined"
        size="small"
        sx={{ marginTop: "10px" }}
        onClick={() => setLearnerPortraitsOpen(true)}
      >
        Show Portraits
      </Button>
    </Grid>
  </Grid>
);

export default LatestPortraitDisplay;
