import { Grid, TextField } from "@mui/material";
import GlowButton from "../../custom/GlowButton";
import usePortraitAnalysisEditing from "./hooks/usePortraitAnalysisEditing";
import useCreateDeletePortrait from "./hooks/useCreateDeletePortrait";

const PortraitAnalysisDisplay = ({
  portraitData,
  setIsEditing,
  creationControlsLocked,
  setCreationControlsLocked,
}) => {
  const { updatePortrait } = usePortraitAnalysisEditing();
  const { deletePortrait } = useCreateDeletePortrait();

  const handlePublishToggleClick = () => {
    if (portraitData.isPublished) {
      // we're going back to draft
      updatePortrait({ ...portraitData, isPublished: false }, "publishToggle");
    } else {
      // we're publishing
      updatePortrait({ ...portraitData, isPublished: true }, "publishToggle");
    }
  };

  const handleDeleteClick = () => {
    deletePortrait(portraitData);
  };

  return (
    <Grid item container direction="column">
      <Grid item sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <TextField
          variant="standard"
          InputProps={{ disableUnderline: true }}
          multiline
          fullWidth
          value={portraitData.analysis}
        />
      </Grid>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
      >
        <Grid item>
          {portraitData.isPublished ? (
            <GlowButton
              variant="outlined"
              onClick={handlePublishToggleClick}
              disabled={creationControlsLocked}
            >
              Set As Draft
            </GlowButton>
          ) : (
            <GlowButton
              variant="outlined"
              onClick={handlePublishToggleClick}
              disabled={creationControlsLocked}
            >
              Publish
            </GlowButton>
          )}
        </Grid>
        <Grid item>
          <GlowButton
            variant="outlined"
            onClick={() => {
              setIsEditing(true);
              setCreationControlsLocked(true);
            }}
            disabled={creationControlsLocked}
          >
            Edit
          </GlowButton>
        </Grid>
        <Grid item>
          <GlowButton
            variant="outlined"
            onClick={handleDeleteClick}
            disabled={creationControlsLocked}
          >
            Delete
          </GlowButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PortraitAnalysisDisplay;
