import _ from "lodash";
import { useState, useEffect, useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  runTransaction,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { useAuth } from "../../../../contexts/AuthContext";

const useSectionNicknameDialog = () => {
  const { currentSectionId } = useContext(SectionContext);
  const { currentUser } = useAuth();
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);

  const [sectionNickname, setSectionNickname] = useState("");
  const [nicknameFieldError, setNicknameFieldError] = useState("");

  const handleChangeNickname = (e) => {
    setSectionNickname(e.target.value);
  };

  const saveNewSectionNickname = async () => {
    try {
      // save the section nickname to the currentUser's section
      // element in their section array

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // isolate original section element (to be removed)
      const originalSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // update the section object
      const updatedSectionObject = {
        ...originalSectionObject,
        nickName: sectionNickname,
      };

      await runTransaction(firestore, async (transaction) => {
        // removal of original sectio element
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalSectionObject),
        });

        // addition of the updated section element
        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedSectionObject),
        });
      });

      setSectionNickname("");
      setNicknameFieldError("");

      setMessage("success", "Section nickname has been successfully saved.");

      setIsLoading(false);
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save the new section nickname: ${err.message}`
      );

      setIsLoading(false);
    }
  };

  return {
    sectionNickname,
    setSectionNickname,
    saveNewSectionNickname,
    nicknameFieldError,
    setNicknameFieldError,
    handleChangeNickname,
  };
};

export default useSectionNicknameDialog;
