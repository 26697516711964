import { useState, useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  collection,
  where,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { LoadingContext } from "../../../../contexts/LoadingContext";

// global for listener object, when active
let unsub = null;

const useLearnerPortraitsDialog = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const [portraits, setPortraits] = useState(null);

  // creates listener for the learners portraits
  const establishPortraitsListener = (learnerId, sectionId) => {
    setIsLoading(true);

    const q = query(
      collection(firestore, "portraits"),
      where("learnerId", "==", learnerId),
      where("sectionId", "==", sectionId),
      orderBy("isPublished", "asc"),
      orderBy("createdOn", "desc")
    );

    unsub = onSnapshot(q, (querySnapshot) => {
      const fetchedPortraits = [];

      querySnapshot.forEach((doc) => {
        fetchedPortraits.push({ id: doc.id, ...doc.data() });
      });

      setPortraits(fetchedPortraits);

      setIsLoading(false);
    });
  };

  const unloadListener = () => {
    if (unsub) {
      unsub();
    }
  };

  return {
    establishPortraitsListener,
    portraits,
    unloadListener,
  };
};

export default useLearnerPortraitsDialog;
