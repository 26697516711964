import React from "react";
import { Navigate } from "react-router-dom";
import { Container } from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import HomePage from "./HomePage";

const LandingContainer = () => {
  const { currentUser } = useAuth();

  return (
    <Container>
      {currentUser && currentUser.role === "teacher" ? (
        <Navigate to="/teacher-landing" replace />
      ) : currentUser && currentUser.role === "guardian" ? (
        <Navigate to="/guardian-landing" replace />
      ) : currentUser && currentUser.role === "admin" ? (
        <Navigate to="/admin-landing" replace />
      ) : (
        <HomePage />
      )}
    </Container>
  );
};

export default LandingContainer;
