import React from "react";
import { AppBar, Toolbar, Grid, Avatar, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useAuth } from "../../contexts/AuthContext";
import gatherCompassLogo from "../../assets/gatherCompassLogo.png";
import NavButtonBar from "./NavButtonBar";
import { localVersion } from "../../config/localVersion";

const GatherNavbar = () => {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const tight = useMediaQuery(theme.breakpoints.down("md"));
  const tighter = useMediaQuery(theme.breakpoints.down("sm"));

  const NavTitleTypography = styled(Typography)(({ theme }) => ({
    fontSize: tighter ? 30 : tight ? 50 : 60,
    fontWeight: 900,
    color: theme.palette.secondary,
  }));

  return (
    <AppBar position="fixed">
      <Toolbar style={{ height: "140px" }}>
        <Grid item container direction="row" alignItems="center">
          <Grid item container direction="column" xs={8}>
            <Grid item container direction="row" alignItems="center">
              <Grid item xl={2} lg={3} sm={4} xs={4} align="center">
                <Avatar
                  alt="logo"
                  variant="square"
                  src={gatherCompassLogo}
                  sx={{
                    width: tighter ? 80 : tight ? 90 : 100,
                    height: tighter ? 80 : tight ? 90 : 100,
                  }}
                />

                <Typography
                  variant="body2"
                  sx={{ fontSize: "12px", fontWeight: "bold" }}
                >
                  {`v${localVersion}`}
                </Typography>
              </Grid>

              {currentUser && (
                <Grid item xl={10} lg={9} sm={8} xs={8}>
                  <NavTitleTypography>Gather</NavTitleTypography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container direction="column" alignItems="flex-end" xs={4}>
            <NavButtonBar />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default GatherNavbar;
