import { useEffect, useState } from "react";
import { Paper, TextField, Grid, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const StarterTextStack = ({
  index,
  starterElement,
  handleChangeStarterText,
}) => {
  const theme = useTheme();
  const [newStarterText, setNewStarterText] = useState("");

  useEffect(() => {
    if (starterElement.starterText && starterElement.starterText.length > 0) {
      setNewStarterText(starterElement.starterText);
    }
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        boxShadow: "none",
        backgroundColor: theme.palette.common.darkColor,
      }}
    >
      <Grid item container direction="column">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={1} align="center">
            <Typography sx={{ fontSize: "30px", color: "white" }}>
              {index + 1}
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <TextField
              key={index}
              value={starterElement.starterText}
              onChange={(e) => handleChangeStarterText(e, index)}
              fullWidth
              multiline
              rows={4}
              inputProps={{
                style: { backgroundColor: "white", color: "black" },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StarterTextStack;
