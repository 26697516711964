import React, { useState, useEffect } from "react";
import {
  Typography,
  Chip,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RateReviewIcon from "@mui/icons-material/RateReview";
import formatDate from "../utils/dateFormatter";
import { useAuth } from "../contexts/AuthContext";

const GuardianPortraitPanel = ({ learnerPortraitEntries }) => {
  const { currentUser } = useAuth();
  const theme = useTheme();

  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Grid
      item
      container
      direction="column"
      sx={{ marginTop: "3em", marginBottom: "5em" }}
    >
      <Grid item>
        <Typography variant="h4" style={{ marginBottom: "0.5em" }}>
          Learner Portraits
        </Typography>
      </Grid>
      <Grid item sx={{ marginBottom: "1.5em" }}>
        <Typography variant="body1">
          These entries are not related to a specific subject, rather they speak
          to the overall aspects of how your child is progressing. In this
          section, teachers often detail the social, emotional, and
          learning-skill development of the young person.
        </Typography>
      </Grid>
      <Grid item container direction="column">
        {learnerPortraitEntries.map((entry, index) => (
          <Accordion
            key={entry.createdOn}
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <Grid item container direction="row" alignItems="center">
                <Grid item>
                  <Typography>{`Published ${formatDate(entry.publishedOn)} by ${
                    entry.teacherFirstName
                  } ${entry.teacherLastName}`}</Typography>
                </Grid>
                {entry.publishedOn > currentUser.previousLogin && (
                  <Grid item>
                    <Chip
                      icon={
                        <RateReviewIcon
                          sx={{
                            marginLeft: "0.5em",
                            "&&": { color: theme.palette.common.glowColor },
                            backgroundColor: theme.palette.common.darkColor,
                          }}
                        />
                      }
                      label="New Portrait Entry"
                      sx={{
                        marginLeft: "1.5em",
                        color: theme.palette.common.glowColor,
                        backgroundColor: theme.palette.common.darkColor,
                        padding: "5px",
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                style={{ whiteSpace: "pre-line" }}
              >{`${entry.analysis}`}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default GuardianPortraitPanel;
