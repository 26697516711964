import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { SectionContext } from "../../../contexts/SectionContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import useLearnerPortraitsDialog from "./hooks/useLearnerPortraitsDialog";
import PortraitDisplayContainer from "./PortraitDisplayContainer";
import GlowButton from "../../custom/GlowButton";

const LearnerPortraitsDialog = ({
  learnerPortraitsOpen,
  handleLearnerPortraitsClose,
  learner,
}) => {
  const { currentSectionId } = useContext(SectionContext);
  const { isLoading } = useContext(LoadingContext);

  const [creationControlsLocked, setCreationControlsLocked] = useState(false);

  const { establishPortraitsListener, portraits, unloadListener } =
    useLearnerPortraitsDialog();

  useEffect(() => {
    if (learnerPortraitsOpen) {
      establishPortraitsListener(learner.learnerId, currentSectionId);

      return () => {
        unloadListener();
      };
    }
  }, [learnerPortraitsOpen]);

  return (
    <>
      <Dialog open={learnerPortraitsOpen} fullWidth maxWidth="xl">
        <DialogTitle>
          <Typography sx={{ fontSize: "40px" }}>
            {`Portraits for ${learner.firstName}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item container direction="column" sx={{ marginBottom: "40px" }}>
            <Grid item>
              <Typography variant="body2" sx={{ fontSize: 20 }}>
                {`Here you will find all of the Learner Portraits that have been created for ${learner.firstName}.`}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ fontSize: 20 }}>
                You can edit and delete existing portraits, as well as set them
                to a draft or published status.
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : portraits && portraits.length > 0 ? (
              portraits.map((portraitData) => (
                <PortraitDisplayContainer
                  key={portraitData.id}
                  portraitData={portraitData}
                  creationControlsLocked={creationControlsLocked}
                  setCreationControlsLocked={setCreationControlsLocked}
                />
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "20px",
                }}
              >{`No portraits have been created yet for ${learner.firstName}...`}</Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <GlowButton
            variant="outlined"
            onClick={handleLearnerPortraitsClose}
            disabled={creationControlsLocked}
          >
            Close
          </GlowButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LearnerPortraitsDialog;
