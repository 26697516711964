import { useState } from "react";
import NarrativeAnalysisDisplay from "./NarrativeAnalysisDisplay";
import NarrativeAnalysisEditing from "./NarrativeAnalysisEditing";

const NarrativeAnalysisContainer = ({
  narrativeData,
  creationControlsLocked,
  setCreationControlsLocked,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <NarrativeAnalysisEditing
      narrativeData={narrativeData}
      setIsEditing={setIsEditing}
      setCreationControlsLocked={setCreationControlsLocked}
    />
  ) : (
    <NarrativeAnalysisDisplay
      narrativeData={narrativeData}
      setIsEditing={setIsEditing}
      creationControlsLocked={creationControlsLocked}
      setCreationControlsLocked={setCreationControlsLocked}
    />
  );
};

export default NarrativeAnalysisContainer;
