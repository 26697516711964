import React, { PureComponent } from "react";
import { Timestamp } from "firebase/firestore";
import dateFormatter from "../../../utils/dateFormatter";
import "../../../assets/printReportStyles.css";
import GatherLogoColor from "../../../assets/gatherLogoColor.png";

export default class GradeLevelFileReport extends PureComponent {
  render() {
    const { learnersToPrint, currentSchoolYear } = this.props;

    return (
      <div>
        {learnersToPrint.map((learner) => (
          <div className="page-break" key={learner.learnerId}>
            <div>
              <img className="logo" src={GatherLogoColor} alt="Gather Logo" />
            </div>
            <h4>
              {`This is a learner file report produced from Gather on ${dateFormatter(
                Timestamp.fromDate(new Date(Date.now()))
              )}.`}
              .
            </h4>
            <div className="learnerHeaderLabels">
              <p>
                <span>Learner Name: </span>
                <span className="fieldValue">{`${learner.firstName} ${learner.lastName}`}</span>
              </p>
              <p>
                <span>Learner ID: </span>
                <span className="fieldValue">{`Learner ID: ${learner.learnerId}`}</span>
              </p>
              <p>
                <span>School Year: </span>
                <span className="fieldValue">{`${currentSchoolYear}`}</span>
              </p>
            </div>
            <div>
              <h3>Last Learner Portrait Entry</h3>
              {learner.lastLearnerPortrait ? (
                <div>
                  <p>{`published on ${dateFormatter(
                    learner.lastLearnerPortrait.publishedOn
                  )} by ${learner.lastLearnerPortrait.teacherFirstName} ${
                    learner.lastLearnerPortrait.teacherLastName
                  }`}</p>
                  <p>{learner.lastLearnerPortrait.analysis}</p>
                </div>
              ) : (
                <p>No learner portrait entries provided.</p>
              )}
            </div>
            <div>
              <h3>Courses:</h3>
            </div>
            {learner.sections.map((section) => (
              <div
                className="courseNarrativeBlock"
                key={`${learner.learnerId}-${section.courseName}`}
              >
                <p className="courseLabel">{`${section.courseName} - Last Narrative Entry`}</p>
                {section.lastNarrative && section.lastNarrative.analysis ? (
                  <div>
                    <p className="timestamp">{`published on ${dateFormatter(
                      section.lastNarrative.publishedOn
                    )} by ${section.lastNarrative.teacherFirstName} ${
                      section.lastNarrative.teacherLastName
                    }`}</p>
                    <p className="courseNarrativeText">
                      {section.lastNarrative.analysis}
                    </p>
                  </div>
                ) : (
                  <p className="courseNarrativeText">No feedback provided.</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
