import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Hidden,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import BlockIcon from "@mui/icons-material/Block";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CourseProgressSelector from "./CourseProgressSelector";
import NarrativeStarterSelector from "./NarrativeStarterSelector";
import { useAuth } from "../../../contexts/AuthContext";
import { SectionContext } from "../../../contexts/SectionContext";
import { NarrativeStartersContext } from "../../../contexts/NarrativeStartersContext";
import LatestNarrativeDisplay from "./LatestNarrativeDisplay";
import LearnerNarrativesDialog from "./LearnerNarrativesDialog";
import GuardianListing from "./GuardianListing";

const LearnerSectionControlRow = ({ learner }) => {
  const { currentUser } = useAuth();
  const { currentStarters } = useContext(NarrativeStartersContext);
  const { currentSectionId } = useContext(SectionContext);

  const [latestNarrativeData, setLatestNarrativeData] = useState(null);
  const [learnerNarrativesOpen, setLearnerNarrativesOpen] = useState(false);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  // determine if we have narrative data that needs displayed for
  // this learner
  useEffect(() => {
    const foundSection = _.find(currentUser.sections, {
      sectionId: currentSectionId,
    });

    const foundLearner = _.find(foundSection.roster, {
      learnerId: learner.learnerId,
    });

    if (foundLearner && foundLearner.latestNarrativeId) {
      // determine if we have a published or draft narrative
      let status = null;

      if (foundLearner.latestNarrativePublishedOn) {
        status = "Published";
      } else {
        status = "Draft";
      }

      setLatestNarrativeData({
        latestNarrativeCreatedOn: foundLearner.latestNarrativeCreatedOn,
        latestNarrativeId: foundLearner.latestNarrativeCreatedOn,
        latestNarrativeLastEditedOn: foundLearner.latestNarrativeCreatedOn,
        latestNarrativePublishedOn: foundLearner.latestNarrativeCreatedOn,
        status,
      });
    } else {
      setLatestNarrativeData({
        latestNarrativeCreatedOn: null,
        latestNarrativeId: null,
        latestNarrativeLastEditedOn: null,
        latestNarrativePublishedOn: null,
      });
    }
  }, [currentUser.sections]);

  const handleLearnerNarrativesClose = () => {
    setLearnerNarrativesOpen(false);
  };

  return (
    <TableRow key={learner.learnerId}>
      <TableCell component="th" scope="row" sx={{ width: 210 }}>
        <Button
          variant="text"
          onClick={() => setLearnerNarrativesOpen(true)}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.common.glowColor,
            },
          }}
        >{`${learner.lastName}, ${learner.firstName}`}</Button>
      </TableCell>
      <TableCell align="center">
        <CourseProgressSelector learner={learner} />
      </TableCell>
      <Hidden lgDown>
        <TableCell align="center">
          {currentStarters &&
          currentStarters.length > 0 &&
          learner.courseRating &&
          learner.courseRating !== "Not Rated" ? (
            <NarrativeStarterSelector
              learner={learner}
              currentStarters={currentStarters}
            />
          ) : (
            <Tooltip
              title="Narrative starters must be configured and the learner's course rating must be set first."
              placement="top"
            >
              <BlockIcon fontSize="large" />
            </Tooltip>
          )}
        </TableCell>
      </Hidden>
      <Hidden lgDown>
        <TableCell sx={{ maxWidth: "200px" }}>
          <Grid item container direction="column">
            <Grid item>
              <GuardianListing learner={learner} />
            </Grid>
          </Grid>
        </TableCell>
      </Hidden>
      <Hidden mdDown>
        {latestNarrativeData && latestNarrativeData.latestNarrativeId ? (
          <TableCell align="center">
            <LatestNarrativeDisplay
              latestNarrativeData={latestNarrativeData}
              setLearnerNarrativesOpen={setLearnerNarrativesOpen}
            />
          </TableCell>
        ) : (
          <TableCell align="center">{"No narratives yet..."}</TableCell>
        )}
      </Hidden>
      <LearnerNarrativesDialog
        learnerNarrativesOpen={learnerNarrativesOpen}
        handleLearnerNarrativesClose={handleLearnerNarrativesClose}
        learner={learner}
      />
    </TableRow>
  );
};

export default LearnerSectionControlRow;
