import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const UnauthedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default UnauthedRoute;
