import _ from "lodash";
import { useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  collection,
  Timestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { useAuth } from "../../../../contexts/AuthContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";

const useCreateDeletePortrait = () => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);

  const createNewPortrait = async (basePortraitText, isPublished, learner) => {
    try {
      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      const newPortraitDocRef = doc(collection(firestore, "portraits"));

      const portraitCollectionData = {
        analysis: basePortraitText,
        createdOn: currentTimestamp,
        isPublished,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: isPublished ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
        teacherFirstName: currentUser.firstName,
        teacherLastName: currentUser.lastName,
      };

      const portraitLearnerData = {
        rootPortraitId: newPortraitDocRef.id,
        analysis: basePortraitText,
        createdOn: currentTimestamp,
        isPublished,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: isPublished ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
        teacherFirstName: currentUser.firstName,
        teacherLastName: currentUser.lastName,
      };

      // homeroom sections are not contained in the learner's
      // section array, so the portraits are stored in a portraits array

      // establish the learner doc ref
      const learnerDocRef = doc(
        firestore,
        "users",
        `${learner.learnerId}-${currentSchoolYear}`
      );

      // we do not create section docs for homerooms

      // we need to update the teacher's section object with the new portrait
      // data for the learner, since teacher sections array contains homerooms

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // find the current teacher section instance, to obtain the original section
      // instance to remove
      const originalTeacherSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // create the updated instance to the teacher section object
      const updatedTeacherSectionObject = {
        ...originalTeacherSectionObject,
        roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
          if (rosteredLearner.learnerId === learner.learnerId) {
            return {
              ...rosteredLearner,
              latestPortraitCreatedOn: currentTimestamp,
              latestPortraitId: newPortraitDocRef.id,
              latestPortraitLastEditedOn: null,
              latestPortraitPublishedOn: isPublished ? currentTimestamp : null,
            };
          } else {
            return rosteredLearner;
          }
        }),
      };

      await runTransaction(firestore, async (transaction) => {
        // save the portrait to the portrait collection
        transaction.set(newPortraitDocRef, portraitCollectionData);

        // for the learner doc, add the new portrait to
        // the portraits array
        transaction.update(learnerDocRef, {
          portraits: arrayUnion(portraitLearnerData),
        });

        // for the teacher doc, remove the original section object from
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        // for the teacher doc, add the updated section object to
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });

      setIsLoading(false);

      setMessage(
        "success",
        "New learner portrait has been saved successfully."
      );

      return { status: "success" };
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save the new learner portrait: ${err.message}`
      );

      setIsLoading(false);

      return { status: "error" };
    }
  };

  const deletePortrait = async (portraitData) => {
    // we may need to determine a new latest portrait, if the portrait
    // being deleted is the current latest. We also need to know if the
    // latest portrait is the last remaining portrait, so...

    try {
      setIsLoading(true);

      let isLatestPortrait = false;
      let isLastPortrait = false;
      let newLatestPortrait = null;
      let newPortraitSummary = null;
      // let sectionDocRef = null;
      // let originalSectionRosterObject = null;
      // let updatedSectionRosterObject = null;
      let teacherDocRef = null;
      let originalTeacherSectionObject = null;
      let updatedTeacherSectionObject = null;

      const currentSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      const foundCurrentLearner = _.find(currentSectionObject.roster, {
        learnerId: portraitData.learnerId,
      });

      if (foundCurrentLearner.latestPortraitId === portraitData.id) {
        isLatestPortrait = true;
      }

      if (isLatestPortrait) {
        // we need to determine if this is the last portrait for this learner
        // in this section, so...

        // establish the learner doc ref
        const learnerDocRef = doc(
          firestore,
          "users",
          `${portraitData.learnerId}-${currentSchoolYear}`
        );

        const learnerDoc = await getDoc(learnerDocRef);

        const learner = learnerDoc.data();

        if (learner.portraits && learner.portraits.length === 1) {
          isLastPortrait = true;
        } else {
          const newOrderedPortraitArray = _.orderBy(
            _.compact(
              learner.portraits.map((portrait) => {
                if (portrait.rootPortraitId !== portraitData.id) {
                  return portrait;
                }
                return null;
              })
            ),
            ["createdOn", "desc"]
          );

          newLatestPortrait = newOrderedPortraitArray.pop();
        }
      }

      // we can now carry out the operations as needed based on the
      // context of the situation...

      // establish the portrait doc ref
      const portraitDocRef = doc(firestore, "portraits", portraitData.id);

      // portrait needs deleted from learner portraits array

      // fetch the original learner doc and isolate the portrait object
      // from the portraits array

      // establish the learner doc ref
      const learnerDocRef = doc(
        firestore,
        "users",
        `${portraitData.learnerId}-${currentSchoolYear}`
      );

      const fetchedLearnerSnap = await getDoc(learnerDocRef);

      const fetchedLearner = {
        id: fetchedLearnerSnap.id,
        ...fetchedLearnerSnap.data(),
      };

      const originalLearnerPortraitObject = _.find(fetchedLearner.portraits, {
        rootPortraitId: portraitData.id,
      });

      // if this is the last or latest, we need to update the
      // teacher (currentUser) sections array
      if (isLatestPortrait) {
        newPortraitSummary = {
          latestPortraitCreatedOn: isLastPortrait
            ? null
            : newLatestPortrait.createdOn,
          latestPortraitId: isLastPortrait
            ? null
            : newLatestPortrait.rootPortraitId,
          latestPortraitLastEditedOn: isLastPortrait
            ? null
            : newLatestPortrait.lastEditedOn,
          latestPortraitPublishedOn: isLastPortrait
            ? null
            : newLatestPortrait.publishedOn,
        };

        // we need to update the teacher's section object with the new portrait
        // data for the learner

        // establish the teacher doc ref
        teacherDocRef = doc(
          firestore,
          "users",
          `${currentUser.teacherId}-${currentSchoolYear}`
        );

        // find the current teacher section instance, to obtain the original section
        // instance to remove
        originalTeacherSectionObject = _.find(currentUser.sections, {
          sectionId: currentSectionId,
        });

        // create the updated instance to the teacher section object
        updatedTeacherSectionObject = {
          ...originalTeacherSectionObject,
          roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
            if (rosteredLearner.learnerId === portraitData.learnerId) {
              return {
                ...rosteredLearner,
                latestPortraitCreatedOn:
                  newPortraitSummary.latestPortraitCreatedOn,
                latestPortraitId: newPortraitSummary.latestPortraitId,
                latestPortraitLastEditedOn:
                  newPortraitSummary.latestPortraitLastEditedOn,
                latestPortraitPublishedOn:
                  newPortraitSummary.latestPortraitPublishedOn,
              };
            } else {
              return rosteredLearner;
            }
          }),
        };
      }

      // we can run the transactions now...
      await runTransaction(firestore, async (transaction) => {
        // portrait doc needs deleted from portraits collection
        transaction.delete(portraitDocRef);

        // for the learner doc, remove the original portrait object from
        // the portraits array
        transaction.update(learnerDocRef, {
          portraits: arrayRemove(originalLearnerPortraitObject),
        });

        if (isLatestPortrait) {
          // for the teacher doc, remove the original section object from
          // their sections array
          transaction.update(teacherDocRef, {
            sections: arrayRemove(originalTeacherSectionObject),
          });

          // for the teacher doc, add the updated section object to
          // their sections array
          transaction.update(teacherDocRef, {
            sections: arrayUnion(updatedTeacherSectionObject),
          });
        }
      });

      setMessage("success", `The portrait has been successfully deleted.`);

      setIsLoading(false);
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to delete the learner portrait: ${err.message}`
      );

      setIsLoading(false);
    }
  };

  return { createNewPortrait, deletePortrait };
};

export default useCreateDeletePortrait;
