import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  runTransaction,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { RatingPropertiesContext } from "../../../../contexts/RatingPropertiesContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { useAuth } from "../../../../contexts/AuthContext";

const useTeacherSectionPanel = () => {
  const { currentUser } = useAuth();
  const { ratingProperties } = useContext(RatingPropertiesContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { currentSectionId } = useContext(SectionContext);
  const { setMessage } = useContext(MessageContext);

  const setAllToMet = async (roster, rating = ratingProperties[2].rating) => {
    const courseRatingUpdateStack = [];
    const learnersSectionUpdateStack = [];

    try {
      setIsLoading(true);

      // fetch original section instance from the teacher sections array, so we
      // can reference the original element for removal and replacement

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // establish the sections doc ref
      const sectionDocRef = doc(
        firestore,
        "sections",
        `${currentSectionId}-${currentSchoolYear}`
      );

      const originalTeacherSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      const updatedTeacherSectionObject = {
        ...originalTeacherSectionObject,
        roster: [
          ...originalTeacherSectionObject.roster.map((rosterElement) => {
            return { ...rosterElement, courseRating: rating };
          }),
        ],
      };

      // fetch relevant section doc, so that we can reference the original roster
      // object for removal and replacement
      const fetchedSectionSnap = await getDoc(sectionDocRef);

      const fetchedSection = {
        id: fetchedSectionSnap.id,
        ...fetchedSectionSnap.data(),
      };

      // update the roster array so that each learner has a
      // "met" rating

      const updatedSectionRoster = fetchedSection.roster.map((learner) => {
        return { ...learner, courseRating: rating };
      });

      // compile the course rating update transactions
      roster.map((learner) => {
        // establish the learner doc ref
        const courseRatingDocRef = doc(
          firestore,
          "learnerCourseRatings",
          `${learner.learnerId}-${currentSectionId}-${currentSchoolYear}`
        );

        courseRatingUpdateStack.push({
          courseRatingDocRef,
          learnerId: learner.learnerId,
        });
      });

      // compile the learner's section update
      await Promise.all(
        roster.map(async (learner) => {
          // establish the learner doc ref
          const learnerDocRef = doc(
            firestore,
            "users",
            `${learner.learnerId}-${currentSchoolYear}`
          );

          // fetch learner sections, so that we can reference the original section
          // object for removal and replacement
          const fetchedLearnerSnap = await getDoc(learnerDocRef);

          const fetchedLearner = {
            id: fetchedLearnerSnap.id,
            ...fetchedLearnerSnap.data(),
          };

          const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
            sectionId: currentSectionId,
          });

          const updatedLearnerSectionObject = {
            ...originalLearnerSectionObject,
            courseRating: rating,
          };

          return learnersSectionUpdateStack.push({
            learnerDocRef,
            originalLearnerSectionObject,
            updatedLearnerSectionObject,
          });
        })
      );

      // begin processing atomic transactions
      await runTransaction(firestore, async (transaction) => {
        // update each of the learner course rating docs
        await Promise.all(
          courseRatingUpdateStack.map((courseRatingData) =>
            transaction.set(courseRatingData.courseRatingDocRef, {
              rating: rating,
              teacherId: currentUser.teacherId,
              learnerId: courseRatingData.learnerId,
              sectionId: currentSectionId,
              schoolYear: currentSchoolYear,
            })
          )
        );

        // update each of the learner's section objects within
        // their section array
        await Promise.all(
          learnersSectionUpdateStack.map((sectionUpdateData) => {
            transaction.update(sectionUpdateData.learnerDocRef, {
              sections: arrayRemove(
                sectionUpdateData.originalLearnerSectionObject
              ),
            });

            transaction.update(sectionUpdateData.learnerDocRef, {
              sections: arrayUnion(
                sectionUpdateData.updatedLearnerSectionObject
              ),
            });
          })
        );

        // update the root section doc, specifically the roster array
        // that contains each learner's course rating
        transaction.update(sectionDocRef, {
          roster: updatedSectionRoster,
        });

        // update the teacher's section object
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });
    } catch (err) {
      setMessage(
        "error",
        `There was an error setting all learners to "Met": ${err.message}`
      );

      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return {
    setAllToMet,
  };
};

export default useTeacherSectionPanel;
