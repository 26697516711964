import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Hidden,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../contexts/AuthContext";
import { SectionContext } from "../../../contexts/SectionContext";
import LatestPortraitDisplay from "./LatestPortraitDisplay";
import CreateNewPortraitDialog from "./CreateNewPortraitDialog";
import LearnerPortraitsDialog from "./LearnerPortraitsDialog";
import GlowButton from "../../custom/GlowButton";

const LearnerPortraitControlRow = ({ learner }) => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);

  const [creationControlsLocked, setCreationControlsLocked] = useState(false);
  const [newPortraitOpen, setNewPortraitOpen] = useState(false);
  const [latestPortraitData, setLatestPortraitData] = useState(null);
  const [learnerPortraitsOpen, setLearnerPortraitsOpen] = useState(false);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  // determine if we have portrait data that needs displayed for
  // this learner
  useEffect(() => {
    const foundSection = _.find(currentUser.sections, {
      sectionId: currentSectionId,
    });

    const foundLearner = _.find(foundSection.roster, {
      learnerId: learner.learnerId,
    });

    if (foundLearner && foundLearner.latestPortraitId) {
      // determine if we have a published or draft portrait
      let status = null;

      if (foundLearner.latestPortraitPublishedOn) {
        status = "Published";
      } else {
        status = "Draft";
      }

      setLatestPortraitData({
        latestPortraitCreatedOn: foundLearner.latestPortraitCreatedOn,
        latestPortraitId: foundLearner.latestPortraitCreatedOn,
        latestPortraitLastEditedOn: foundLearner.latestPortraitCreatedOn,
        latestPortraitPublishedOn: foundLearner.latestPortraitCreatedOn,
        status,
      });
    } else {
      setLatestPortraitData({
        latestPortraitCreatedOn: null,
        latestPortraitId: null,
        latestPortraitLastEditedOn: null,
        latestPortraitPublishedOn: null,
      });
    }
  }, [currentUser.sections]);

  const handleNewPortraitClose = () => {
    setNewPortraitOpen(false);
  };

  const handleLearnerPortraitsClose = () => {
    setLearnerPortraitsOpen(false);
  };

  return (
    <TableRow key={learner.learnerId}>
      <TableCell component="th" scope="row" sx={{ width: 210 }}>
        <Button
          variant="text"
          onClick={() => {
            setLearnerPortraitsOpen(true);
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.common.glowColor,
            },
          }}
        >{`${learner.lastName}, ${learner.firstName}`}</Button>
      </TableCell>
      <TableCell align="center">
        <GlowButton
          disabled={creationControlsLocked}
          variant="outlined"
          onClick={() => {
            setNewPortraitOpen(true);
          }}
        >
          Create New Portrait
        </GlowButton>
      </TableCell>
      <Hidden mdDown>
        {latestPortraitData && latestPortraitData.latestPortraitId ? (
          <TableCell align="center">
            <LatestPortraitDisplay
              latestPortraitData={latestPortraitData}
              setLearnerPortraitsOpen={setLearnerPortraitsOpen}
            />
          </TableCell>
        ) : (
          <TableCell align="center">{"No portraits yet..."}</TableCell>
        )}
      </Hidden>
      <CreateNewPortraitDialog
        newPortraitOpen={newPortraitOpen}
        handleNewPortraitClose={handleNewPortraitClose}
        learner={learner}
      />
      <LearnerPortraitsDialog
        learnerPortraitsOpen={learnerPortraitsOpen}
        handleLearnerPortraitsClose={handleLearnerPortraitsClose}
        learner={learner}
      />
    </TableRow>
  );
};

export default LearnerPortraitControlRow;
