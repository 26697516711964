import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

// TODO :: ACTIVATE APP CHECK AFTER DEPLOYMENT
// generate a debug token to the console, for registration at project settings
// but only if we are in a development environment
// if (process.env.NODE_ENV === "development") {
//   window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

// Pass the reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key that is set in the Firebase console.
// initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHAV3_SITE_KEY),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true,
// });

// Initialize services with the 'firebaseApp' property
export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
