import React from "react";
import _ from "lodash";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Hidden,
  Typography,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LearnerSectionControlRow from "./LearnerSectionControlRow";
import useTeacherSectionPanel from "./hooks/useTeacherSectionPanel";

const TeacherSectionPanel = ({ value, index, roster }) => {
  const { setAllToMet } = useTeacherSectionPanel();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  const handleSetAllToMet = () => {
    setAllToMet(roster);
  };

  return (
    <Grid
      role="tabpanel"
      id={`section-panel-${index}`}
      aria-labelledby={`section-tab-${index}`}
    >
      {value === index && (
        <Grid item>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontSize: "15px" }}>Learner</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Grid item container direction="column">
                      <Grid item>
                        <Typography sx={{ fontSize: "15px" }}>
                          Course Rating
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={handleSetAllToMet}
                          color="secondary"
                        >
                          Set All to Met
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <Hidden lgDown>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "15px" }}>
                        Narrative Starter
                      </Typography>
                    </TableCell>
                  </Hidden>
                  <Hidden lgDown>
                    <TableCell
                      align="center"
                      sx={{ fontSize: "15px", maxWidth: "200px" }}
                    >
                      <Typography sx={{ fontSize: "15px" }}>
                        Guardian Info
                      </Typography>
                    </TableCell>
                  </Hidden>
                  <Hidden mdDown>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "15px" }}>
                        Latest Narrative Created
                      </Typography>
                    </TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {roster.map((learner) => (
                  <LearnerSectionControlRow
                    learner={learner}
                    key={learner.learnerId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default TeacherSectionPanel;
