import _ from "lodash";
import { useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  runTransaction,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { RatingPropertiesContext } from "../../../../contexts/RatingPropertiesContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { useAuth } from "../../../../contexts/AuthContext";

const useCourseProgressSelector = () => {
  const { currentUser } = useAuth();
  const { ratingProperties } = useContext(RatingPropertiesContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { currentSectionId } = useContext(SectionContext);

  const fetchCurrentRating = (learner) => {
    if (learner.courseRating === null) {
      return ratingProperties[0];
    }

    const foundCourseRating = _.find(ratingProperties, {
      rating: learner.courseRating,
    });

    return foundCourseRating;
  };

  const setCourseRating = async (rating, learner) => {
    setIsLoading(true);

    // establish course rating doc ref
    const courseRatingDocRef = doc(
      firestore,
      "learnerCourseRatings",
      `${learner.learnerId}-${currentSectionId}-${currentSchoolYear}`
    );

    // establish the sections doc ref
    const sectionDocRef = doc(
      firestore,
      "sections",
      `${currentSectionId}-${currentSchoolYear}`
    );

    // establish the learner doc ref
    const learnerDocRef = doc(
      firestore,
      "users",
      `${learner.learnerId}-${currentSchoolYear}`
    );

    // establish the teacher doc ref
    const teacherDocRef = doc(
      firestore,
      "users",
      `${currentUser.teacherId}-${currentSchoolYear}`
    );

    // fetch learner sections, so that we can reference the original section
    // object for removal and replacement
    const fetchedLearnerSnap = await getDoc(learnerDocRef);

    const fetchedLearner = {
      id: fetchedLearnerSnap.id,
      ...fetchedLearnerSnap.data(),
    };

    const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
      sectionId: currentSectionId,
    });

    const updatedLearnerSectionObject = {
      ...originalLearnerSectionObject,
      courseRating: rating,
    };

    // fetch relevant section doc, so that we can reference the original roster
    // object for removal and replacement
    const fetchedSectionSnap = await getDoc(sectionDocRef);

    const fetchedSection = {
      id: fetchedSectionSnap.id,
      ...fetchedSectionSnap.data(),
    };

    const originalSectionRosterObject = _.find(fetchedSection.roster, {
      learnerId: learner.learnerId,
    });

    const updatedSectionRosterObject = {
      ...originalSectionRosterObject,
      courseRating: rating,
    };

    // fetch original section instance from the teacher sections array, so we
    // can reference the original element for removal and replacement

    const originalTeacherSectionObject = _.find(currentUser.sections, {
      sectionId: currentSectionId,
    });

    const updatedTeacherSectionObject = {
      ...originalTeacherSectionObject,
      roster: [
        ...originalTeacherSectionObject.roster.map((rosterElement) => {
          if (rosterElement.learnerId === learner.learnerId) {
            return { ...rosterElement, courseRating: rating };
          }
          return rosterElement;
        }),
      ],
    };

    try {
      await runTransaction(firestore, async (transaction) => {
        transaction.set(courseRatingDocRef, {
          rating: rating,
          teacherId: currentUser.teacherId,
          learnerId: learner.learnerId,
          sectionId: currentSectionId,
          schoolYear: currentSchoolYear,
        });

        transaction.update(sectionDocRef, {
          roster: arrayRemove(originalSectionRosterObject),
        });

        transaction.update(sectionDocRef, {
          roster: arrayUnion(updatedSectionRosterObject),
        });

        transaction.update(learnerDocRef, {
          sections: arrayRemove(originalLearnerSectionObject),
        });

        transaction.update(learnerDocRef, {
          sections: arrayUnion(updatedLearnerSectionObject),
        });

        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });
      setIsLoading(false);
    } catch (e) {
      console.log("Transaction failed: ", e);
      setIsLoading(false);
    }
  };

  return {
    fetchCurrentRating,
    setCourseRating,
  };
};

export default useCourseProgressSelector;
