import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { AppBar, Tabs, Tab, Grid, Hidden, ButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../contexts/AuthContext";
import { SectionContext } from "../../../contexts/SectionContext";
import { LearnerContext } from "../../../contexts/LearnerContext";
import TeacherSectionPanel from "./TeacherSectionPanel";
import TeacherPortraitPanel from "./TeacherPortraitPanel";
import NarrativeStartersDialog from "./NarrativeStartersDialog";
import SectionNicknameDialog from "./SectionNicknameDialog";
import CourseDescriptionDialog from "./CourseDescriptionDialog";
import GlowButton from "../../custom/GlowButton";

const TeacherLandingPage = () => {
  const { currentUser } = useAuth();
  const { currentSectionId, setCurrentSectionId } = useContext(SectionContext);
  const { setCurrentLearnerId } = useContext(LearnerContext);

  const [value, setValue] = useState(0);
  const [orderedSections, setOrderedSections] = useState(null);
  const [homeroomIsSelected, setHomeroomIsSelected] = useState(false);
  const [startersOpen, setStartersOpen] = useState(false);
  const [sectionNicknameOpen, setSectionNicknameOpen] = useState(false);
  const [courseDescriptionOpen, setCourseDescriptionOpen] = useState(false);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  // be sure our sections are ordered by sectionId
  useEffect(() => {
    setOrderedSections(_.orderBy(currentUser.sections, ["sectionId", "asc"]));
  }, [currentUser.sections]);

  // set the value for the tab selector if we have a currentSectionId
  useEffect(() => {
    if (currentSectionId && orderedSections) {
      // find index where currentSectionId resides
      const index = _.findIndex(orderedSections, {
        sectionId: currentSectionId,
      });

      // we need to check for the homeroom tab being selected
      // so that we can mutate the landing page UI accordingly
      const selectedTabData = orderedSections[index];

      if (
        selectedTabData.sectionId.slice(0, 6) === "AUCEHR" ||
        selectedTabData.sectionId.slice(0, 6) === "AUMEHR"
      ) {
        setHomeroomIsSelected(true);
      } else if (homeroomIsSelected) {
        setHomeroomIsSelected(false);
      }

      setValue(index);
    } else {
      setHomeroomIsSelected(true);
    }
  }, [currentSectionId, orderedSections]);

  const handleChange = (e, newValue) => {
    setCurrentSectionId(orderedSections[newValue].sectionId);
  };

  const handleOpenStarterConfig = () => {
    setStartersOpen(true);
  };

  const handleOpenSectionNickname = () => {
    setSectionNicknameOpen(true);
  };

  return (
    currentSectionId &&
    orderedSections && (
      <>
        <Grid container style={{ marginTop: "10em" }}>
          <Grid item container direction="column">
            <AppBar
              position="static"
              sx={{ backgroundColor: theme.palette.common.darkColor }}
            >
              <Tabs
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: theme.palette.common.glowColor,
                  },
                }}
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: theme.palette.common.glowColor,
                  },
                }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
                aria-label="scrollable teacher section tabs"
              >
                {orderedSections.map((section) => {
                  return (
                    <Tab
                      sx={{ color: theme.palette.common.mediumColor }}
                      key={section.sectionId}
                      label={
                        section.sectionId.slice(0, 6) === "AUCEHR" ||
                        section.sectionId.slice(0, 6) === "AUMEHR"
                          ? "Learner Portraits"
                          : section.nickName
                          ? section.nickName
                          : section.courseName
                      }
                      id={`section-tab-${section.sectionId}`}
                      aria-controls={`section-tabpanel-${section.sectionId}`}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </Grid>
          {!homeroomIsSelected && (
            <Grid container direction="column">
              <Grid
                item
                align="center"
                sx={{ marginTop: "15px", marginBottom: "15px" }}
              >
                <ButtonGroup variant="text" aria-label="text button group">
                  <GlowButton onClick={handleOpenSectionNickname}>
                    Change Section Name
                  </GlowButton>
                  <GlowButton onClick={() => setCourseDescriptionOpen(true)}>
                    Show Course Description
                  </GlowButton>
                  <Hidden mdDown>
                    <GlowButton onClick={handleOpenStarterConfig}>
                      Configure Narrative Starters
                    </GlowButton>
                  </Hidden>
                </ButtonGroup>
              </Grid>
            </Grid>
          )}
          <Grid item container direction="column">
            {currentUser &&
              orderedSections.map((section) =>
                section.sectionId.slice(0, 6) === "AUCEHR" ||
                section.sectionId.slice(0, 6) === "AUMEHR" ? (
                  <TeacherPortraitPanel
                    key={section.sectionId}
                    value={section.sectionId}
                    index={currentSectionId}
                    roster={_.orderBy(section.roster, ["lastName", "asc"])}
                    currentUser={currentUser}
                    setCurrentLearnerId={setCurrentLearnerId}
                  />
                ) : (
                  <TeacherSectionPanel
                    key={section.sectionId}
                    value={section.sectionId}
                    index={currentSectionId}
                    roster={_.orderBy(section.roster, ["lastName", "asc"])}
                    currentUser={currentUser}
                    setCurrentLearnerId={setCurrentLearnerId}
                  />
                )
              )}
          </Grid>
        </Grid>
        <NarrativeStartersDialog
          startersOpen={startersOpen}
          handleStartersClose={() => setStartersOpen(false)}
        />
        <SectionNicknameDialog
          sectionNicknameOpen={sectionNicknameOpen}
          handleSectionNicknameClose={() => setSectionNicknameOpen(false)}
        />
        <CourseDescriptionDialog
          courseDescriptionOpen={courseDescriptionOpen}
          setCourseDescriptionOpen={setCourseDescriptionOpen}
        />
      </>
    )
  );
};

export default TeacherLandingPage;
