import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Chip, Tooltip, Zoom } from "@mui/material";
import { RatingPropertiesContext } from "../../../contexts/RatingPropertiesContext";

const CourseRatingChip = ({ rating, tooltipPos = "top" }) => {
  const { ratingProperties } = useContext(RatingPropertiesContext);
  const [color, setColor] = useState(null);
  const [title, setTitle] = useState(null);

  // determine rating properties from the rating value
  useEffect(() => {
    // check to see if the existind rating is in the ratingProperties
    // if it is, compile accordingly, if not, consider the rating to be absent.
    const matchedRating = _.find(ratingProperties, { rating });

    if (matchedRating) {
      const properties = _.find(ratingProperties, { rating });
      setColor(properties.color);
      setTitle(properties.title);
    } else {
      console.log(ratingProperties[0].title);
      setColor(ratingProperties[0].color);
      setTitle(ratingProperties[0].title);
    }
  }, [rating, ratingProperties]);

  return (
    <Tooltip
      TransitionComponent={Zoom}
      title={title ? title : "Not Yet Rated"}
      placement={tooltipPos}
    >
      <Chip
        sx={{
          backgroundColor: color ? color : "grey",
          color: color === "green" ? "white" : "black",
        }}
        label={
          rating &&
          (rating === ratingProperties[0].rating ||
            rating === ratingProperties[1].rating ||
            rating === ratingProperties[2].rating)
            ? rating
            : "NR"
        }
      />
    </Tooltip>
  );
};

export default CourseRatingChip;
