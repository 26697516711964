import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useAuth } from "../../../contexts/AuthContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import useFetchAdminData from "./hooks/useFetchAdminData";
import AdminLandingPage from "./AdminLandingPage";

const AdminLandingContainer = () => {
  const { currentUser } = useAuth();
  const { fetchAdminSummaryData } = useFetchAdminData();
  const { setIsLoading } = useContext(LoadingContext);
  const [adminSummaryData, setAdminSummaryData] = useState(null);
  const [learnerListing, setLearnerListing] = useState(null);

  const handleAdminDataFetch = async (gradeLevels) => {
    const fetchedSummaryData = await fetchAdminSummaryData(gradeLevels);

    setAdminSummaryData(fetchedSummaryData);

    // setup learner listing for search field on landing page
    const allLearnersDuplicated = [];

    // start by getting listing data for each gradelevel involved
    fetchedSummaryData.map((gradeLevel) => {
      // compile all learnerIds across all sections
      return gradeLevel.sections.map((section) => {
        return section.learners.map((learner) => {
          allLearnersDuplicated.push({
            firstName: learner.firstName,
            lastName: learner.lastName,
            learnerId: learner.learnerId,
          });
          return null;
        });
      });
    });

    // consolidate down to just unique learnerIds and set the listing accordingly
    const allLearnersUnique = _.uniqBy(allLearnersDuplicated, "learnerId");
    setLearnerListing(_.orderBy(allLearnersUnique, ["lastName"], ["asc"]));

    setIsLoading(false);
  };

  // when component mounts check for admin summary data
  useEffect(() => {
    if (!adminSummaryData && currentUser) {
      setIsLoading(true);
      handleAdminDataFetch(currentUser.gradeLevels);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    adminSummaryData &&
    adminSummaryData.length > 0 &&
    learnerListing &&
    learnerListing.length > 0 && (
      <AdminLandingPage
        learnerListing={learnerListing}
        adminSummaryData={adminSummaryData}
      />
    )
  );
};

export default AdminLandingContainer;
