import { Routes, Route } from "react-router-dom";
import GatherNavbar from "./components/navbar/GatherNavbar";
import UnauthedRoute from "./components/routes/UnauthedRoute";
import MessagingPanel from "./components/MessagingPanel";
import AdminRoute from "./components/routes/AdminRoute";
import TeacherRoute from "./components/routes/TeacherRoute";
import GuardianRoute from "./components/routes/GuardianRoute";
import StaffSigninPage from "./components/staff/StaffSigninPage";
import GuardianSigninPage from "./components/guardian/GuardianSignInPage";
import GuardianSignupPage from "./components/guardian/GuardianSignupPage";
import ForgotPasswordPage from "./components/guardian/ForgotPasswordPage";
import LandingContainer from "./components/LandingContainer";
import AdminLandingContainer from "./components/staff/admin/AdminLandingContainer";
import TeacherLandingPage from "./components/staff/teacher/TeacherLandingPage";
import GuardianLandingPage from "./components/guardian/GuardianLandingPage";
import WorkingIndicator from "./components/WorkingIndicator";
import AdminReportsSelector from "./components/staff/admin/AdminReportsSelector";
import SupportPage from "./components/SupportPage";

function App() {
  return (
    <>
      <GatherNavbar />
      <MessagingPanel />
      <WorkingIndicator />
      <Routes>
        <Route exact path="/" element={<LandingContainer />} />
        <Route
          path="/staff-signin"
          element={
            <UnauthedRoute>
              <StaffSigninPage />
            </UnauthedRoute>
          }
        />
        <Route
          path="/guardian-signin"
          element={
            <UnauthedRoute>
              <GuardianSigninPage />
            </UnauthedRoute>
          }
        />
        <Route
          path="/guardian-signup"
          element={
            <UnauthedRoute>
              <GuardianSignupPage />
            </UnauthedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <UnauthedRoute>
              <ForgotPasswordPage />
            </UnauthedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <UnauthedRoute>
              <SupportPage />
            </UnauthedRoute>
          }
        />
        <Route path="/support" element={<SupportPage />} />
        <Route
          path="/admin-landing"
          element={
            <AdminRoute>
              <AdminLandingContainer />
            </AdminRoute>
          }
        />
        <Route
          path="/admin-reporting-selector"
          element={
            <AdminRoute>
              <AdminReportsSelector />
            </AdminRoute>
          }
        />
        <Route
          path="/teacher-landing"
          element={
            <TeacherRoute>
              <TeacherLandingPage />
            </TeacherRoute>
          }
        />
        <Route
          path="/guardian-landing"
          element={
            <GuardianRoute>
              <GuardianLandingPage />
            </GuardianRoute>
          }
        />
      </Routes>
    </>
  );
}

export default App;
