import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import GlowButton from "../../custom/GlowButton";
import useCourseDescriptionDialog from "../../hooks/useCourseDescriptionDialog";

const CourseDescriptionDialog = ({
  courseDescriptionOpen,
  setCourseDescriptionOpen,
}) => {
  const { courseDescription, fetchCourseDescription } =
    useCourseDescriptionDialog();

  const [loading, setLoading] = useState(false);

  const fetchDescription = async () => {
    setLoading(true);

    const fetchedDescription = await fetchCourseDescription();

    setLoading(false);
  };

  useEffect(() => {
    if (courseDescriptionOpen) {
      fetchDescription();
    }
  }, [courseDescriptionOpen]);

  return (
    <Dialog open={courseDescriptionOpen} maxWidth="sm">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>Course Description</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <Grid item>
            <Typography sx={{ fontSize: 16 }}>
              {loading
                ? "Loading..."
                : courseDescription
                ? courseDescription
                : "Course description not found..."}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <GlowButton
          variant="outlined"
          onClick={() => setCourseDescriptionOpen(false)}
        >
          Close
        </GlowButton>
      </DialogActions>
    </Dialog>
  );
};

export default CourseDescriptionDialog;
