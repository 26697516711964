import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import useCourseDescriptionDialog from "../hooks/useCourseDescriptionDialog";

const CourseDescriptionDialog = ({
  courseDescriptionOpen,
  setCourseDescriptionOpen,
  courseName,
}) => {
  const { courseDescription, fetchCourseDescription } =
    useCourseDescriptionDialog();

  const [loading, setLoading] = useState(false);

  const fetchDescription = async () => {
    setLoading(true);

    const fetchedDescription = await fetchCourseDescription();

    setLoading(false);
  };

  useEffect(() => {
    if (courseDescriptionOpen) {
      fetchDescription();
    }
  }, [courseDescriptionOpen]);

  return (
    <Dialog
      onClose={() => setCourseDescriptionOpen(false)}
      aria-labelledby="customized-dialog-title"
      open={courseDescriptionOpen}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={() => setCourseDescriptionOpen(false)}
      >
        {courseDescription.length > 0 ? courseName : "Loading..."}
      </DialogTitle>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <DialogContent dividers>
          <Typography gutterBottom>
            {courseDescription.length > 0 && courseDescription}
          </Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          autoFocus
          onClick={() => setCourseDescriptionOpen(false)}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CourseDescriptionDialog;
