import _ from "lodash";
import { useContext, useState } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  Timestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { useAuth } from "../../../../contexts/AuthContext";

const useNarrativeAnalysisEditing = () => {
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);
  const { currentSectionId } = useContext(SectionContext);
  const { currentUser } = useAuth();

  const updateNarrative = async (
    updatedNarrativeData,
    operationType = null
  ) => {
    // need to set timestamp for relevant date fields
    const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

    // returns the published on value if we have an operation affecting
    // that value
    const getPublishedOnValue = () => {
      if (
        operationType &&
        operationType === "publishToggle" &&
        updatedNarrativeData.isPublished === true
      )
        return currentTimestamp;
      else if (
        operationType &&
        operationType === "publishToggle" &&
        updatedNarrativeData.isPublished === false
      ) {
        return null;
      } else return updatedNarrativeData.publishedOn;
    };

    setIsLoading(true);

    try {
      // toggling the published status does not equate to an edit, so...
      const updatedNarrative = {
        analysis: updatedNarrativeData.analysis,
        createdOn: updatedNarrativeData.createdOn,
        isPublished: updatedNarrativeData.isPublished,
        learnerId: updatedNarrativeData.learnerId,
        schoolYear: updatedNarrativeData.schoolYear,
        sectionId: updatedNarrativeData.sectionId,
        teacherId: updatedNarrativeData.teacherId,
        lastEditedOn:
          operationType && operationType === "publishToggle"
            ? updatedNarrativeData.lastEditedOn
            : currentTimestamp,
        publishedOn: getPublishedOnValue(),
      };

      // setup vars for updating latest narrative metadata, if needed
      let isLatestNarrative = false;
      let originalSectionRosterInstance = null;
      let updatedSectionRosterInstance = null;
      let originalTeacherSectionInstance = null;
      let updatedTeacherSectionInstance = null;

      // check to see if this is the latest created narrative for the learner
      // check in the teacher section first
      const foundTeacherSection = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      const foundLearner = _.find(foundTeacherSection.roster, {
        learnerId: updatedNarrative.learnerId,
      });

      if (foundLearner.latestNarrativeId === updatedNarrativeData.id) {
        // this is the learner's latest narrative, so...
        isLatestNarrative = true;
      }

      const updatedNarrativeDocRef = doc(
        firestore,
        "narratives",
        updatedNarrativeData.id
      );

      const learnerDocRef = doc(
        firestore,
        "users",
        `${updatedNarrative.learnerId}-${currentSchoolYear}`
      );

      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      const sectionDocRef = doc(
        firestore,
        "sections",
        `${currentSectionId}-${currentSchoolYear}`
      );

      // fetch the learner doc so that we have the original section array
      const fetchedLearner = await getDoc(learnerDocRef);
      const learnerData = fetchedLearner.data();

      const updatedLearnerNarrative = {
        analysis: updatedNarrative.analysis,
        createdOn: updatedNarrative.createdOn,
        isPublished: updatedNarrative.isPublished,
        lastEditedOn: updatedNarrative.lastEditedOn,
        learnerId: updatedNarrative.learnerId,
        publishedOn: getPublishedOnValue(),
        schoolYear: updatedNarrative.schoolYear,
        sectionId: updatedNarrative.sectionId,
        teacherId: updatedNarrative.teacherId,
        rootNarrativeId: updatedNarrativeData.id,
      };

      // isolate the original learner's section object, to be removed
      const originalLearnerSectionObject = _.find(learnerData.sections, {
        sectionId: currentSectionId,
      });

      // provide an updated learner section object
      const updatedLearnerSectionObject = {
        ...originalLearnerSectionObject,
        narratives: originalLearnerSectionObject.narratives.map((narrative) => {
          if (narrative.rootNarrativeId === updatedNarrativeData.id) {
            return updatedLearnerNarrative;
          }
          return narrative;
        }),
      };

      // if we're dealing with a latest created narrative, we will have
      // to update the sections object, and the teacher's roster object
      if (isLatestNarrative) {
        // fetch the section doc so we have the original roster array
        const fetchedSection = (await getDoc(sectionDocRef)).data();

        // isolate the original roster object, for removal
        const originalSectionRoster = fetchedSection.roster;

        // isolate the original learner instance
        originalSectionRosterInstance = _.find(originalSectionRoster, {
          learnerId: updatedNarrative.learnerId,
        });

        updatedSectionRosterInstance = {
          ...originalSectionRosterInstance,
          latestNarrativeLastEditedOn: updatedNarrative.lastEditedOn,
          latestNarrativePublishedOn: getPublishedOnValue(),
        };

        // isolate the original teacher section roster
        originalTeacherSectionInstance = _.find(currentUser.sections, {
          sectionId: currentSectionId,
        });

        updatedTeacherSectionInstance = {
          ...originalTeacherSectionInstance,
          roster: originalTeacherSectionInstance.roster.map(
            (learnerInstance) => {
              if (learnerInstance.learnerId === updatedNarrative.learnerId) {
                return {
                  ...learnerInstance,
                  latestNarrativeLastEditedOn: updatedNarrative.lastEditedOn,
                  latestNarrativePublishedOn: getPublishedOnValue(),
                };
              }
              return learnerInstance;
            }
          ),
        };
      }
      await runTransaction(firestore, async (transaction) => {
        // update the narrative doc in the narrative collection
        transaction.update(updatedNarrativeDocRef, updatedNarrative);

        // remove the original section item from the learner's sections array
        transaction.update(learnerDocRef, {
          sections: arrayRemove(originalLearnerSectionObject),
        });

        // add the updated section item back
        transaction.update(learnerDocRef, {
          sections: arrayUnion(updatedLearnerSectionObject),
        });

        // only enact the following transactions if needed
        if (isLatestNarrative) {
          // remove the original roster item from the teacher's roster array
          transaction.update(teacherDocRef, {
            sections: arrayRemove(originalTeacherSectionInstance),
          });

          // add the updated roster item back
          transaction.update(teacherDocRef, {
            sections: arrayUnion(updatedTeacherSectionInstance),
          });

          // remove the original roster item from the section doc
          transaction.update(sectionDocRef, {
            roster: arrayRemove(originalSectionRosterInstance),
          });

          // add the updated section item back
          transaction.update(sectionDocRef, {
            roster: arrayUnion(updatedSectionRosterInstance),
          });
        }
      });

      setIsLoading(false);

      setMessage("success", "The narrative has been updated successfully.");
    } catch (err) {
      setIsLoading(false);

      setMessage(
        "error",
        "There was an error updating the narrative: ",
        err.message
      );
    }
  };

  return {
    updateNarrative,
  };
};

export default useNarrativeAnalysisEditing;
