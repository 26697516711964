import React, { PureComponent } from "react";
import { Timestamp } from "firebase/firestore";
import dateFormatter from "../../../utils/dateFormatter";
import "../../../assets/printReportStyles.css";
import GatherLogoColor from "../../../assets/gatherLogoColor.png";

export default class GradeLevelArchiveReport extends PureComponent {
  render() {
    const { learnersToPrint, currentSchoolYear } = this.props;

    return (
      <div>
        {learnersToPrint.map((learner) => (
          <div className="page-break" key={learner.learnerId}>
            <div>
              <img className="logo" src={GatherLogoColor} alt="Gather Logo" />
            </div>
            <h4>
              {`This is a learner file report produced from Gather on ${dateFormatter(
                Timestamp.fromDate(new Date(Date.now()))
              )}.`}
            </h4>
            <div className="learnerHeaderLabels">
              <p>
                <span>Learner Name: </span>
                <span className="fieldValue">{`${learner.firstName} ${learner.lastName}`}</span>
              </p>
              <p>
                <span>Learner ID: </span>
                <span className="fieldValue">{`${learner.learnerId}`}</span>
              </p>
              <p>
                <span>School Year: </span>
                <span className="fieldValue">{`${currentSchoolYear}`}</span>
              </p>
            </div>
            <div>
              <h2>Historical Learner Portrait Entries</h2>
              {learner.learnerPortraits && learner.learnerPortraits.length ? (
                learner.learnerPortraits.map((portrait) => (
                  <div key={portrait.publishedOn}>
                    <p className="timestamp">{`Published on ${dateFormatter(
                      portrait.publishedOn
                    )} by ${portrait.teacherFirstName} ${
                      portrait.teacherLastName
                    }`}</p>
                    <p className="courseNarrativeText">{portrait.analysis}</p>
                  </div>
                ))
              ) : (
                <p>No learner portraits were provided.</p>
              )}
            </div>
            <div>
              <h2>Historical Course Narratives</h2>
            </div>
            {learner.sections.map((section) => (
              <div
                className="courseNarrativeBlock"
                key={`${learner.learnerId}-${section.courseName}`}
              >
                <h3>{section.courseName}</h3>
                {section.narratives && section.narratives.length ? (
                  section.narratives.map((narrative) => (
                    <div key={narrative.publishedOn}>
                      <p className="timestamp">{`Published on ${dateFormatter(
                        narrative.publishedOn
                      )} by ${narrative.teacherFirstName} ${
                        narrative.teacherLastName
                      }`}</p>
                      <p className="courseNarrativeText">
                        {narrative.analysis}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>No narratives were provided for this course.</p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
