import { useEffect, useContext } from "react";
import _ from "lodash";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import useSectionNicknameDialog from "./hooks/useSectionNicknameDialog";
import { useAuth } from "../../../contexts/AuthContext";
import { SectionContext } from "../../../contexts/SectionContext";

const SectionNicknameDialog = ({
  sectionNicknameOpen,
  handleSectionNicknameClose,
}) => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);
  const {
    sectionNickname,
    setSectionNickname,
    saveNewSectionNickname,
    nicknameFieldError,
    setNicknameFieldError,
    handleChangeNickname,
  } = useSectionNicknameDialog();

  useEffect(() => {
    // see if the teacher already has a section nickname set
    const foundSection = _.find(currentUser.sections, {
      sectionId: currentSectionId,
    });

    if (foundSection && foundSection.nickName) {
      setSectionNickname(foundSection.nickName);
    } else {
      setSectionNickname("");
    }
  }, [currentSectionId, currentUser.sections]);

  const handleSaveNewSectionNickname = async () => {
    if (!sectionNickname) {
      return setNicknameFieldError(
        "The section nickname cannot be blank when trying to save it."
      );
    }
    await saveNewSectionNickname();

    handleSectionNicknameClose();
  };

  return (
    <Dialog open={sectionNicknameOpen} maxWidth="sm">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>Custom Section Name</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <Grid item>
            <Typography sx={{ fontSize: 16 }}>
              You can set a custom name for this section. Only you will see the
              custom name that you provide here, not guardians nor other staff
              members.
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: "30px" }}>
            <TextField
              fullWidth
              error={
                nicknameFieldError && nicknameFieldError.length > 0
                  ? true
                  : false
              }
              id="section_nickname"
              label="Section Nickname"
              name="section_nickname"
              value={sectionNickname}
              onChange={handleChangeNickname}
              onKeyDown={(e) => {
                if (e.key === "Enter" && sectionNickname.length > 0) {
                  handleSaveNewSectionNickname();
                }
              }}
              helperText={
                nicknameFieldError && nicknameFieldError.length > 0
                  ? nicknameFieldError
                  : null
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setNicknameFieldError("");
            setSectionNickname("");
            handleSectionNicknameClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSaveNewSectionNickname}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SectionNicknameDialog;
