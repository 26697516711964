import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Typography, Grid } from "@mui/material";
import GradeLevelSummaryPanel from "./GradeLevelSummaryPanel";

const AdminSummaryPanel = ({ adminSummaryData, ratingProperties }) => {
  const [structuredData, setStructuredData] = useState(null);

  useEffect(() => {
    stageSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stageSummaryData = () => {
    // compile formatted summary data for admin dashboard
    const formattedSummaryData = adminSummaryData.map((gradeLevelData) => {
      // stack all learners across all sections into an array for searching
      const allLearnersAllSections = [];

      // for each grade level, find the relevant course names
      const allCourseNamesDuplicated = gradeLevelData.sections.map(
        (section) => {
          section.learners.map((learner) => {
            allLearnersAllSections.push({
              courseName: section.courseName,
              sectionId: section.sectionId,
              firstName: learner.firstName,
              lastName: learner.lastName,
              courseRating: learner.courseRating,
              learnerId: learner.learnerId,
            });
            return null;
          });
          return section.courseName;
        }
      );

      const gradeLevelCourseNames = _.uniq(allCourseNamesDuplicated);

      const gradeLevelCourseData = gradeLevelCourseNames.map((courseName) => {
        const foundLearners = _.filter(allLearnersAllSections, {
          courseName,
        });

        // establish variables dynamically, using map of key value pairs
        const dynamicRatingsData = [];

        ratingProperties.map((property) => {
          dynamicRatingsData[property.rating] = {
            color: property.color,
            code: property.code,
            description: property.description,
            sortOrder: property.sortOrder,
            title: property.title,
            learners: [],
          };
          return null;
        });

        // compile learner rating data elements
        foundLearners.map((learner) => {
          if (
            learner.courseRating !== null &&
            (learner.courseRating === "Met" ||
              learner.courseRating === "Not Yet")
          ) {
            // we have to add the learner to the appropriate object in the dynamically created rating array
            // quite easy using the key value pairs
            dynamicRatingsData[learner.courseRating].learners.push({
              firstName: learner.firstName,
              lastName: learner.lastName,
              learnerId: learner.learnerId,
            });
          } else {
            dynamicRatingsData[ratingProperties[0].rating].learners.push({
              firstName: learner.firstName,
              lastName: learner.lastName,
              learnerId: learner.learnerId,
            });
          }
          return null;
        });

        // setup a store for the array elements come from the dynamically generated map
        const ratingDataArray = [];

        // extract the values from the map, storing in the array
        for (const [key, value] of Object.entries(dynamicRatingsData)) {
          ratingDataArray.push({ ...{ value }.value, rating: { key }.key });
        }

        return {
          courseName,
          learners: foundLearners,
          courseRatings: ratingDataArray,
        };
      });

      return {
        gradeLevel: gradeLevelData.gradeLevel,
        courseData: gradeLevelCourseData,
      };
    });

    setStructuredData(formattedSummaryData);
  };

  return (
    <Grid item container direction="column" style={{ marginTop: "3em" }}>
      <Grid item>
        <Typography variant="h3">Gather Administrative Summary</Typography>
      </Grid>
      {structuredData ? (
        structuredData.map((gradeLevelData) => {
          return (
            <GradeLevelSummaryPanel
              key={gradeLevelData.gradeLevel}
              gradeLevelData={gradeLevelData}
            />
          );
        })
      ) : (
        <Grid item>
          <Typography>Loading...</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AdminSummaryPanel;
