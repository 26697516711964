import React, { useRef, useContext } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Avatar,
  Grid,
  Typography,
  Container,
  TextField,
  Button,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useAuth } from "../../contexts/AuthContext";
import { MessageContext } from "../../contexts/MessageContext";
import { LoadingContext } from "../../contexts/LoadingContext";

const ForgotPasswordPage = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const { sendPasswordReset } = useAuth();
  const { setMessage } = useContext(MessageContext);
  const { setIsLoading } = useContext(LoadingContext);

  const emailRef = useRef();

  const handlePasswordReset = async (e) => {
    if (emailRef.current.value.length === 0) {
      return setMessage(
        "error",
        "An email address is required to reset your password...",
        5000
      );
    }

    try {
      setIsLoading(true);

      await sendPasswordReset(emailRef.current.value);

      setIsLoading(false);

      await setMessage(
        "info",
        `Please follow the password reset instructions sent to your email address...`,
        9000
      );

      navigate("/");
    } catch (err) {
      setIsLoading(false);

      return setMessage(
        "error",
        `Check the email address you provided, as it is not recognized by our system.`,
        5000
      );
    }
  };

  return (
    <Container maxWidth="sm" sx={{ marginTop: "200px" }}>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h2" variant="body1">
            Please provide the email account used to register your Gather
            account. Instruction for resetting your password will be sent to
            this email address.
          </Typography>
        </Grid>
        <Grid item sx={{ width: "100%", marginTop: "2em" }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            inputRef={emailRef}
            autoFocus
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handlePasswordReset}
            fullWidth
            variant="contained"
            color="primary"
          >
            Reset Password
          </Button>
        </Grid>
        <Grid item sx={{ marginTop: "20px" }}>
          <Link component={RouterLink} to="/" variant="body2">
            Cancel
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgotPasswordPage;
