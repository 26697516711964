import {
  Typography,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  List,
  Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import GuardianIcon from "@mui/icons-material/AdminPanelSettings";

const GuardianListing = ({ learner }) => {
  const theme = useTheme();

  return (
    <List disablePadding>
      {learner.guardians ? (
        learner.guardians.map((guardian) => (
          <ListItem key={guardian.email}>
            <Tooltip
              title={
                guardian.lastLogin
                  ? `Last login on ${guardian.lastLogin
                      .toDate()
                      .toDateString()}`
                  : "Not registered"
              }
              placement="left"
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: guardian.lastLogin
                      ? theme.palette.common.darkColor
                      : "grey",
                  }}
                >
                  <GuardianIcon
                    sx={{
                      color: guardian.lastLogin
                        ? theme.palette.common.glowColor
                        : "grey",
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
            </Tooltip>
            <ListItemText
              primary={`${guardian.firstName} ${guardian.lastName}`}
              secondary={
                guardian.phoneNumber
                  ? `${guardian.phoneNumber}, ${guardian.email}`
                  : `${guardian.email}`
              }
            />
          </ListItem>
        ))
      ) : (
        <Typography>No guardians...</Typography>
      )}
    </List>
  );
};

export default GuardianListing;
