import React from "react";
import _ from "lodash";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Hidden,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import LearnerPortraitControlRow from "./LearnerPortraitControlRow";

const TeacherPortraitPanel = ({ value, index, roster }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid
      role="tabpanel"
      id={`portrait-panel-${index}`}
      aria-labelledby={`portrait-tab-${index}`}
    >
      {value === index && (
        <Grid item>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="customized portrait table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography sx={{ fontSize: "15px" }}>Learner</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography sx={{ fontSize: "15px" }}></Typography>
                  </TableCell>
                  <Hidden lgDown>
                    <TableCell align="center">
                      <Typography sx={{ fontSize: "15px" }}>
                        Latest Portrait Created
                      </Typography>
                    </TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {roster.map((learner) => (
                  <LearnerPortraitControlRow
                    learner={learner}
                    key={learner.learnerId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default TeacherPortraitPanel;
