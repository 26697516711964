import _ from "lodash";
import { useContext } from "react";
import { firestore } from "../../../../firebaseInit";
import {
  doc,
  getDoc,
  collection,
  Timestamp,
  runTransaction,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";
import { useAuth } from "../../../../contexts/AuthContext";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { SectionContext } from "../../../../contexts/SectionContext";
import { MessageContext } from "../../../../contexts/MessageContext";
import { RatingPropertiesContext } from "../../../../contexts/RatingPropertiesContext";

const injectNameIntoText = (combinedText, learnerFirstName) =>
  combinedText.replaceAll("$LEARNER_NAME", learnerFirstName);

const useStarterSelectedDialog = () => {
  const { currentUser } = useAuth();
  const { currentSectionId } = useContext(SectionContext);
  const { setIsLoading } = useContext(LoadingContext);
  const { setMessage } = useContext(MessageContext);
  const { ratingProperties } = useContext(RatingPropertiesContext);

  const createNarrativeForLearner = async (
    selectedStarter,
    learner,
    status
  ) => {
    try {
      // compile the narrative data using the learner's course
      // rating and the narrative starter text
      const foundCourseProperty = _.find(ratingProperties, {
        rating: learner.courseRating,
      });

      // we need to find and replace the variable for the learner's name
      // within the property decription and starter text

      const nameInjectedText = injectNameIntoText(
        `${foundCourseProperty.description}\n\n${selectedStarter.starterText}`,
        learner.firstName
      );

      const analysis = nameInjectedText;

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      const newNarrativeDocRef = doc(collection(firestore, "narratives"));

      const narrativeCollectionData = {
        analysis,
        createdOn: currentTimestamp,
        isPublished: status === "published" ? true : false,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: status === "published" ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
      };

      const narrativeLearnerData = {
        rootNarrativeId: newNarrativeDocRef.id,
        analysis,
        createdOn: currentTimestamp,
        isPublished: status === "published" ? true : false,
        lastEditedOn: null,
        learnerId: learner.learnerId,
        publishedOn: status === "published" ? currentTimestamp : null,
        schoolYear: currentSchoolYear,
        sectionId: currentSectionId,
        teacherId: currentUser.teacherId,
      };

      // fetch the learner's doc so that we have the original section
      // object to remove from their sections array

      // establish the learner doc ref
      const learnerDocRef = doc(
        firestore,
        "users",
        `${learner.learnerId}-${currentSchoolYear}`
      );

      const fetchedLearnerSnap = await getDoc(learnerDocRef);

      const fetchedLearner = {
        id: fetchedLearnerSnap.id,
        ...fetchedLearnerSnap.data(),
      };

      const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
        sectionId: currentSectionId,
      });

      // update the original learner section object to include the new narrative
      // if there are no narratives, just add the new one,
      // otherwise, append the narrative to the array of narratives
      let updatedLearnerSectionObject = null;

      if (
        originalLearnerSectionObject.narratives &&
        originalLearnerSectionObject.narratives.length > 0
      ) {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: [
            ...originalLearnerSectionObject.narratives,
            narrativeLearnerData,
          ],
        };
      } else {
        updatedLearnerSectionObject = {
          ...originalLearnerSectionObject,
          narratives: [narrativeLearnerData],
        };
      }

      // we need to update the sections collection with the new narrative
      // data for the learner

      // fetch the current section doc, so we can have the original section
      // array object to remove

      // establish the section doc ref
      const sectionDocRef = doc(
        firestore,
        "sections",
        `${currentSectionId}-${currentSchoolYear}`
      );

      const fetchedSectionSnap = await getDoc(sectionDocRef);

      const fetchedSection = {
        id: fetchedSectionSnap.id,
        ...fetchedSectionSnap.data(),
      };

      const originalSectionRosterObject = _.find(fetchedSection.roster, {
        learnerId: learner.learnerId,
      });

      const updatedSectionRosterObject = {
        ...originalSectionRosterObject,
        latestNarrativeCreatedOn: currentTimestamp,
        latestNarrativeId: newNarrativeDocRef.id,
        latestNarrativeLastEditedOn: null,
        latestNarrativePublishedOn:
          status === "published" ? currentTimestamp : null,
      };

      // we need to update the teacher's section object with the new narrative
      // data for the learner

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // find the current teacher section instance, to obtain the original section
      // instance to remove
      const originalTeacherSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // create the updated instance to the teacher section object
      const updatedTeacherSectionObject = {
        ...originalTeacherSectionObject,
        roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
          if (rosteredLearner.learnerId === learner.learnerId) {
            return {
              ...rosteredLearner,
              latestNarrativeCreatedOn: currentTimestamp,
              latestNarrativeId: newNarrativeDocRef.id,
              latestNarrativeLastEditedOn: null,
              latestNarrativePublishedOn:
                status === "published" ? currentTimestamp : null,
            };
          } else {
            return rosteredLearner;
          }
        }),
      };

      await runTransaction(firestore, async (transaction) => {
        // save the narrative to the narrative collection
        transaction.set(newNarrativeDocRef, narrativeCollectionData);

        // for the learner doc, remove the original section object from
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayRemove(originalLearnerSectionObject),
        });

        // for the learner doc, add the updated section object to
        // the sections array
        transaction.update(learnerDocRef, {
          sections: arrayUnion(updatedLearnerSectionObject),
        });

        // for the sections doc, remove the original roster element
        transaction.update(sectionDocRef, {
          roster: arrayRemove(originalSectionRosterObject),
        });

        // for the sections doc, add the updated roster element
        transaction.update(sectionDocRef, {
          roster: arrayUnion(updatedSectionRosterObject),
        });

        // for the teacher doc, remove the original section object from
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        // for the teacher doc, add the updated section object to
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });

      setIsLoading(false);

      setMessage(
        "success",
        "New learner narrative has been saved successfully."
      );

      return { status: "success" };
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save the new learner narrative: ${err.message}`
      );

      setIsLoading(false);

      return { status: "error" };
    }
  };

  const saveNarrativeForLearner = async (
    selectedStarter,
    learner,
    status = "draft"
  ) => {
    setIsLoading(true);

    return await createNarrativeForLearner(selectedStarter, learner, status);
  };

  const saveNarrativeForAllLearners = async (
    selectedStarter,
    status = "draft"
  ) => {
    setIsLoading(true);
    try {
      // setup transaction stacks
      const learnersUpdateStack = [];
      const narrativesCreationStack = [];

      const currentTimestamp = Timestamp.fromDate(new Date(Date.now()));

      // isolate section roster
      const foundSection = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // determine the learners that have course ratings
      const learners = _.compact(
        foundSection.roster.map((rosterElement) => {
          if (
            rosterElement.courseRating &&
            rosterElement.courseRating !== "Not Rated"
          ) {
            return rosterElement;
          } else {
            return null;
          }
        })
      );

      // each learner will have to have their own narrative id, and
      // each learner will generate a new narrative doc that will need
      // included into their own sections array, and the sections collection
      await Promise.all(
        learners.map(async (learner) => {
          // compile the narrative data using the learner's course
          // rating and the narrative starter text
          const foundCourseProperty = _.find(ratingProperties, {
            rating: learner.courseRating,
          });

          // we need to find and replace the variable for the learner's name
          // within the property decription and starter text

          const nameInjectedText = injectNameIntoText(
            `${foundCourseProperty.description}\n\n${selectedStarter.starterText}`,
            learner.firstName
          );

          const analysis = nameInjectedText;

          const newNarrativeDocRef = doc(collection(firestore, "narratives"));

          const narrativeCollectionData = {
            analysis,
            createdOn: currentTimestamp,
            isPublished: status === "published" ? true : false,
            lastEditedOn: null,
            learnerId: learner.learnerId,
            publishedOn: status === "published" ? currentTimestamp : null,
            schoolYear: currentSchoolYear,
            sectionId: currentSectionId,
            teacherId: currentUser.teacherId,
            newNarrativeDocRef,
            id: newNarrativeDocRef.id,
          };

          const narrativeLearnerData = {
            analysis,
            createdOn: currentTimestamp,
            isPublished: status === "published" ? true : false,
            lastEditedOn: null,
            learnerId: learner.learnerId,
            publishedOn: status === "published" ? currentTimestamp : null,
            schoolYear: currentSchoolYear,
            sectionId: currentSectionId,
            teacherId: currentUser.teacherId,
            rootNarrativeId: newNarrativeDocRef.id,
          };

          // send the narrative doc to the narratives creation stack
          narrativesCreationStack.push(narrativeCollectionData);

          // fetch the learner's doc so that we have the original section
          // object to remove from their sections array

          // establish the learner doc ref
          const learnerDocRef = doc(
            firestore,
            "users",
            `${learner.learnerId}-${currentSchoolYear}`
          );

          const fetchedLearnerSnap = await getDoc(learnerDocRef);

          const fetchedLearner = {
            id: fetchedLearnerSnap.id,
            ...fetchedLearnerSnap.data(),
          };

          const originalLearnerSectionObject = _.find(fetchedLearner.sections, {
            sectionId: currentSectionId,
          });

          // update the original learner section object to include the new narrative
          // if there are no narratives, just add the new one,
          // otherwise, append the narrative to the array of narratives
          let updatedLearnerSectionObject = null;

          if (
            originalLearnerSectionObject.narratives &&
            originalLearnerSectionObject.narratives.length > 0
          ) {
            updatedLearnerSectionObject = {
              ...originalLearnerSectionObject,
              narratives: [
                ...originalLearnerSectionObject.narratives,
                narrativeLearnerData,
              ],
            };
          } else {
            updatedLearnerSectionObject = {
              ...originalLearnerSectionObject,
              narratives: [narrativeLearnerData],
            };
          }

          learnersUpdateStack.push({
            learnerDocRef,
            originalLearnerSectionObject,
            updatedLearnerSectionObject,
          });
        })
      );

      // we compile the updates for section and teacher outside of the
      // mapping of each learner, since the updates will be the same
      // for all eligible learners. We need to be sure that non-eligible
      // learners do not have a narrative created for them

      // fetch the current section doc, so we can have the original section
      // array object to remove

      // establish the section doc ref
      const sectionDocRef = doc(
        firestore,
        "sections",
        `${currentSectionId}-${currentSchoolYear}`
      );

      const fetchedSectionSnap = await getDoc(sectionDocRef);

      const fetchedSection = {
        id: fetchedSectionSnap.id,
        ...fetchedSectionSnap.data(),
      };

      const originalSectionRosterObject = fetchedSection.roster;

      const updatedSectionRosterObject = originalSectionRosterObject.map(
        (rosteredLearner) => {
          const foundNarrative = _.find(narrativesCreationStack, {
            learnerId: rosteredLearner.learnerId,
          });
          if (foundNarrative) {
            return {
              ...rosteredLearner,
              latestNarrativeCreatedOn: currentTimestamp,
              latestNarrativeId: foundNarrative.id,
              latestNarrativeLastEditedOn: null,
              latestNarrativePublishedOn:
                status === "published" ? currentTimestamp : null,
            };
          }
          return rosteredLearner;
        }
      );

      // establish the teacher doc ref
      const teacherDocRef = doc(
        firestore,
        "users",
        `${currentUser.teacherId}-${currentSchoolYear}`
      );

      // find the current teacher section instance, to obtain the original section
      // instance to remove
      const originalTeacherSectionObject = _.find(currentUser.sections, {
        sectionId: currentSectionId,
      });

      // create the updated instance to the teacher section object
      const updatedTeacherSectionObject = {
        ...originalTeacherSectionObject,
        roster: originalTeacherSectionObject.roster.map((rosteredLearner) => {
          // every eligible rostered learner will get updated
          // we need the narrative doc ref for the learner
          const foundNarrative = _.find(narrativesCreationStack, {
            learnerId: rosteredLearner.learnerId,
          });
          if (foundNarrative) {
            return {
              ...rosteredLearner,
              latestNarrativeCreatedOn: currentTimestamp,
              latestNarrativeId: foundNarrative.id,
              latestNarrativeLastEditedOn: null,
              latestNarrativePublishedOn:
                status === "published" ? currentTimestamp : null,
            };
          }
          return rosteredLearner;
        }),
      };

      // and now we present: a shitload of atomic transactions
      await runTransaction(firestore, async (transaction) => {
        // create all new narrative docs
        await Promise.all(
          narrativesCreationStack.map((narrativeData) =>
            transaction.set(narrativeData.newNarrativeDocRef, {
              analysis: narrativeData.analysis,
              createdOn: narrativeData.createdOn,
              isPublished: narrativeData.isPublished,
              lastEditedOn: narrativeData.lastEditedOn,
              learnerId: narrativeData.learnerId,
              publishedOn: narrativeData.publishedOn,
              schoolYear: narrativeData.schoolYear,
              sectionId: narrativeData.sectionId,
              teacherId: narrativeData.teacherId,
            })
          )
        );

        // update all learner section elements
        await Promise.all(
          learnersUpdateStack.map((learnerUpdateData) => {
            transaction.update(learnerUpdateData.learnerDocRef, {
              sections: arrayRemove(
                learnerUpdateData.originalLearnerSectionObject
              ),
            });

            transaction.update(learnerUpdateData.learnerDocRef, {
              sections: arrayUnion(
                learnerUpdateData.updatedLearnerSectionObject
              ),
            });
          })
        );

        // we need to update the section doc's roster for
        // the section upon which we are running the operation
        transaction.update(sectionDocRef, {
          roster: updatedSectionRosterObject,
        });

        // we need to update the teacher's section roster for
        // the section upon which we are running the operation

        // for the teacher doc, remove the original section object from
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayRemove(originalTeacherSectionObject),
        });

        // for the teacher doc, add the updated section object to
        // their sections array
        transaction.update(teacherDocRef, {
          sections: arrayUnion(updatedTeacherSectionObject),
        });
      });

      setIsLoading(false);

      setMessage(
        "success",
        "New learner narratives have been saved successfully."
      );
    } catch (err) {
      setMessage(
        "error",
        `There was an error trying to save the new learner narratives: ${err.message}`
      );

      setIsLoading(false);
    }
  };

  return { saveNarrativeForLearner, saveNarrativeForAllLearners };
};

export default useStarterSelectedDialog;
