import { useState, useContext, useEffect } from "react";
import { firestore } from "../../../../firebaseInit";
import { doc, getDoc } from "firebase/firestore";
import { LoadingContext } from "../../../../contexts/LoadingContext";
import { currentSchoolYear } from "../../../../config/currentSchoolYear";

const useNarrativeDisplayContainer = () => {
  const { setIsLoading } = useContext(LoadingContext);

  const [teacherName, setTeacherName] = useState(null);

  const teacherNameLookup = async (teacherId) => {
    // fetch teacher name details using their id
    const userDocRef = doc(
      firestore,
      "users",
      `${teacherId}-${currentSchoolYear}`
    );
    const userDocSnap = await getDoc(userDocRef);

    const teacherData = userDocSnap.data();

    setTeacherName(teacherData.firstName + " " + teacherData.lastName);
  };

  return {
    teacherName,
    teacherNameLookup,
  };
};

export default useNarrativeDisplayContainer;
