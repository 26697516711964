import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import NarrativeQtySelector from "./NarrativeQtySelector";
import StarterTextStack from "./StarterTextStack";
import useNarrativeStartersDialog from "./hooks/useNarrativeStartersDialog";
import { NarrativeStartersContext } from "../../../contexts/NarrativeStartersContext";
import { MessageContext } from "../../../contexts/MessageContext";

const NarrativeStartersDialog = ({ startersOpen, handleStartersClose }) => {
  const { currentStarters } = useContext(NarrativeStartersContext);
  const { setMessage } = useContext(MessageContext);

  const { saveNarrativeStarters, starterConfigOptions } =
    useNarrativeStartersDialog();
  const [starterConfigIndex, setStarterConfigIndex] = useState(0);
  const [starterConfig, setStarterConfig] = useState(null);
  const [updatedStarterArray, setUpdatedStarterArray] = useState([]);

  // we need to establish local state with incoming starter data, if present
  useEffect(() => {
    if (startersOpen) {
      if (currentStarters && currentStarters.length > 0) {
        // initialize state to represent existing starters
        setStarterConfigIndex(currentStarters.length);
        setStarterConfig(starterConfigOptions[currentStarters.length]);
        setUpdatedStarterArray(currentStarters);
      } else {
        // we have no starters yet, so...
        setStarterConfigIndex(0);
        setStarterConfig(starterConfigOptions[0]);
        setUpdatedStarterArray([]);
      }
    } else {
      // dialog is no longer open, so reset the state
      setStarterConfigIndex(0);
      setStarterConfig(null);
      setUpdatedStarterArray([]);
    }
  }, [startersOpen]);

  const handleIncreaseStarters = () => {
    if (starterConfigIndex < 5) {
      setStarterConfig(starterConfigOptions[starterConfigIndex + 1]);
      setStarterConfigIndex(starterConfigIndex + 1);
      setUpdatedStarterArray([
        ...updatedStarterArray,
        {
          starterText: "",
          sortOrder: starterConfigIndex,
        },
      ]);
    }
  };

  const handleDecreaseStarters = () => {
    if (starterConfigIndex > 0) {
      setStarterConfig(starterConfigOptions[starterConfigIndex - 1]);
      setStarterConfigIndex(starterConfigIndex - 1);
      const updatedupdatedStarterArray = [...updatedStarterArray];
      updatedupdatedStarterArray.pop();
      setUpdatedStarterArray(updatedupdatedStarterArray);
    }
  };

  const handleChangeStarterText = (e, index) => {
    // target the correct narrative starter in the narrativeStarter
    // and mutate the starterText
    setUpdatedStarterArray([
      ...updatedStarterArray.map((starterInstance) => {
        if (starterInstance.sortOrder === index) {
          return { ...starterInstance, starterText: e.target.value };
        }
        return { ...starterInstance };
      }),
    ]);
  };

  const handleSaveNarrativeStarters = async () => {
    // if no changes were made, just close the dialog without a save action
    if (updatedStarterArray === currentStarters) {
      return handleStartersClose();
    }

    // check for no text within each of the starters
    let isEmpty = false;

    updatedStarterArray.map((instance) => {
      if (instance.starterText.length === 0) {
        isEmpty = true;
      }
    });

    if (isEmpty) {
      return setMessage(
        "error",
        "At least one of your starters has no text. Please make adjustments and try saving again."
      );
    } else {
      const result = await saveNarrativeStarters(updatedStarterArray);
      if (result && result.status === "success") {
        return handleStartersClose();
      }
    }
  };

  return (
    <Dialog open={startersOpen} maxWidth="lg">
      <DialogTitle>
        <Typography sx={{ fontSize: "40px" }}>Narrative Starters</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <Grid item>
            <Typography sx={{ fontSize: 18 }}>
              A narrative starter provides foundational text when providing
              feedback for learners. You can have up to five narrative starters,
              with each describing a particular level of learner progress within
              the course.
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 15, marginTop: "1em" }}>
              Please note, you can use the variable $LEARNER_NAME anywhere in
              your narrative text, and it will inject the learner's first name
              in the exact place that you use $LEARNER_NAME.
            </Typography>
          </Grid>
          {starterConfig && (
            <NarrativeQtySelector
              starterConfig={starterConfig}
              handleIncreaseStarters={handleIncreaseStarters}
              handleDecreaseStarters={handleDecreaseStarters}
            />
          )}
        </Grid>
        <Grid item container direction="column" spacing={2} marginTop="10px">
          {updatedStarterArray.length > 0 &&
            updatedStarterArray.map((starterElement, index) => {
              return (
                <Grid item key={index}>
                  <StarterTextStack
                    index={index}
                    starterElement={starterElement}
                    handleChangeStarterText={handleChangeStarterText}
                  />
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handleStartersClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSaveNarrativeStarters}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NarrativeStartersDialog;
