import React, { useState, useEffect, createContext } from "react";
import { firestore } from "../firebaseInit";
import { useAuth } from "../contexts/AuthContext";
import { currentSchoolYear } from "../config/currentSchoolYear";
import { doc, onSnapshot } from "firebase/firestore";

export const LearnerContext = createContext();

export const LearnerProvider = (props) => {
  const { currentUser } = useAuth();
  const [currentLearnerId, setCurrentLearnerId] = useState(null);
  const [currentLearner, setCurrentLearner] = useState(null);

  // establishes a snapshot listener, which gets changes to the user profile in realtime
  const setLearnerListener = (learnerId) => {
    if (!learnerId) {
      return () => setCurrentLearner(null);
    }

    return onSnapshot(
      doc(firestore, "users", `${learnerId}-${currentSchoolYear}`),
      (doc) => {
        return setCurrentLearner({ ...doc.data() });
      },
      (error) => {
        // ...
      }
    );
  };

  // be sure we have a currentLearner established for a teacher
  useEffect(() => {
    if (currentUser && currentUser.role) {
      // guardians will need an initial currentLearner set for their UI
      if (
        (!currentLearner && currentUser.role === "guardian") ||
        (!currentLearnerId && currentLearner && currentUser.role === "guardian")
      ) {
        if (!currentUser.learners || currentUser.learners.length === 0) {
          return setCurrentLearner(null);
        }
        // set their current learner to the first learner in their learners array
        const unsubscribe = setLearnerListener(
          currentUser.learners[0].learnerId
        );
        return () => {
          unsubscribe();
        };
      } else {
        if (!currentLearnerId && currentLearner) {
          return setCurrentLearner(null);
        }
        // we have a currentUser and the currentLearnerId changed to something
        // not null, so set their current learner to the new currentLearnerId
        const unsubscribe = setLearnerListener(currentLearnerId);
        return () => {
          unsubscribe();
        };
      }
    } else {
      setLearnerListener(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentLearnerId]);

  return (
    <LearnerContext.Provider
      value={{
        currentLearnerId,
        currentLearner,
        setCurrentLearnerId,
        setCurrentLearner,
      }}
    >
      {props.children}
    </LearnerContext.Provider>
  );
};
