import { Typography, Grid, Paper, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import useNarrativeDisplayContainer from "./hooks/useNarrativeDisplayContainer";
import formatDate from "../../../utils/dateFormatter";
import PortraitAnalysisContainer from "./PortraitAnalysisContainer";

const PortraitDisplayItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
}));

const PortraitDisplayContainer = ({
  portraitData,
  creationControlsLocked,
  setCreationControlsLocked,
}) => {
  const { teacherName, teacherNameLookup } = useNarrativeDisplayContainer();
  const theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    teacherNameLookup(portraitData.teacherId);
  }, []);

  return (
    <Grid item container direction="column">
      <Grid item>
        <PortraitDisplayItem elevation={8}>
          <Grid item container direction="column" sx={{ padding: "20px" }}>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item container direction="column" sm={6}>
                <Grid item>
                  <Typography>{`Written By: ${
                    teacherName ? `${teacherName}` : ""
                  }`}</Typography>
                </Grid>
                <Grid item>{`Created On: ${formatDate(
                  portraitData.createdOn
                )}`}</Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                sm={6}
                align={smDown ? "left" : "right"}
              >
                <Grid item>
                  {portraitData.isPublished ? "Published" : "Draft"}
                </Grid>
                <Grid item>
                  {portraitData.lastEditedOn
                    ? `Last Edited On: ${formatDate(portraitData.lastEditedOn)}`
                    : `Not edited`}
                </Grid>
              </Grid>
            </Grid>
            <PortraitAnalysisContainer
              portraitData={portraitData}
              creationControlsLocked={creationControlsLocked}
              setCreationControlsLocked={setCreationControlsLocked}
            />
          </Grid>
        </PortraitDisplayItem>
      </Grid>
    </Grid>
  );
};

export default PortraitDisplayContainer;
