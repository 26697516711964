import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { SectionContext } from "../../../contexts/SectionContext";
import { LoadingContext } from "../../../contexts/LoadingContext";
import useLearnerNarrativesDialog from "./hooks/useLearnerNarrativesDialog";
import CreateNewNarrativeDialog from "./CreateNewNarrativeDialog";
import NarrativeDisplayContainer from "./NarrativeDisplayContainer";
import GlowButton from "../../custom/GlowButton";

const LearnerNarrativesDialog = ({
  learnerNarrativesOpen,
  handleLearnerNarrativesClose,
  learner,
}) => {
  const { currentSectionId } = useContext(SectionContext);
  const { isLoading } = useContext(LoadingContext);
  const [creationControlsLocked, setCreationControlsLocked] = useState(false);
  const [newNarrativeOpen, setNewNarrativeOpen] = useState(false);

  const handleNewNarrativeClose = () => {
    setNewNarrativeOpen(false);
  };

  const { establishNarrativesListener, narratives, unloadListener } =
    useLearnerNarrativesDialog();

  useEffect(() => {
    if (learnerNarrativesOpen) {
      establishNarrativesListener(learner.learnerId, currentSectionId);

      return () => {
        unloadListener();
      };
    }
  }, [learnerNarrativesOpen]);

  return (
    <>
      <Dialog open={learnerNarrativesOpen} fullWidth maxWidth="xl">
        <DialogTitle>
          <Typography sx={{ fontSize: "40px" }}>
            {`Narratives for ${learner.firstName}`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item container direction="column">
            <Grid item sx={{ marginBottom: "40px" }}>
              <Typography variant="body2" sx={{ fontSize: 20 }}>
                Here you can create new narratives for a learner, and edit
                existing narratives.
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : narratives && narratives.length > 0 ? (
              narratives.map((narrativeData) => (
                <NarrativeDisplayContainer
                  key={narrativeData.id}
                  narrativeData={narrativeData}
                  creationControlsLocked={creationControlsLocked}
                  setCreationControlsLocked={setCreationControlsLocked}
                />
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "20px",
                }}
              >{`No narratives have been created yet for ${learner.firstName}...`}</Typography>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <GlowButton
            disabled={
              !learner.courseRating ||
              learner.courseRating === "Not Rated" ||
              creationControlsLocked
            }
            variant="outlined"
            onClick={() => {
              setNewNarrativeOpen(true);
            }}
          >
            Create New Narrative
          </GlowButton>
          <GlowButton
            variant="outlined"
            onClick={handleLearnerNarrativesClose}
            disabled={creationControlsLocked}
          >
            Close
          </GlowButton>
        </DialogActions>
      </Dialog>

      <CreateNewNarrativeDialog
        newNarrativeOpen={newNarrativeOpen}
        handleNewNarrativeClose={handleNewNarrativeClose}
        learner={learner}
      />
    </>
  );
};

export default LearnerNarrativesDialog;
